import { css } from "@styled-system/css";
import { memo } from "react";

interface SchemaIframeProps {
  url: string;
}

const SchemaIframe: React.FC<SchemaIframeProps> = memo(({ url }) => (
  <iframe
    className={css({
      width: "100%",
      height: "100%",
      border: "1px solid base.lightGray",
      rounded: "md",
    })}
    src={url}
    title="Data Schema Content"
  />
));
SchemaIframe.displayName = "SchemaIframe";

export { SchemaIframe };
