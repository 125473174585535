import { PageHeader } from "@components/PageHeader";
import { useAuth } from "@hooks/useAuth";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { css } from "@styled-system/css";
import { Box } from "@styled-system/jsx";
import { Button, Heading, InputField, PageLayout, useToast } from "doowii-ui";
import { useEffect } from "react";
import { Form, useActionData, useNavigation, useSubmit } from "react-router-dom";

import { DeleteAccountDialog } from "./DeleteAccountDialog";
import { SettingsActionResponse } from "./settingsAction";

const boxStyles = css({
  padding: "xl",
  border: "1px solid",
  borderColor: "base.lightGray",
  borderRadius: "lg",
  backgroundColor: "white",
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const ActionButtonStyles = css({
  marginTop: "ld",
  width: "fit-content",
});

const Settings = () => {
  const { _ } = useLingui();
  const { userDocument } = useAuth();
  const navigation = useNavigation();
  const { toast } = useToast();
  const actionData = useActionData() as SettingsActionResponse | null;
  const submit = useSubmit();

  const isSubmitting = navigation.state === "submitting";

  useEffect(() => {
    if (actionData?.message) {
      toast({
        status: actionData.success ? "success" : "error",
        title: actionData.message,
      });
    }
  }, [actionData, toast, _]);

  const handleDeleteAccount = () => {
    submit({ intent: "deleteAccount" }, { method: "post" });
  };

  return (
    <>
      <PageHeader description={_(msg`Manage account Settings`)} title={_(msg`Doowii - Settings`)} />
      <PageLayout title={_(msg`Settings`)}>
        {" "}
        <Box
          css={{
            w: "full",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "ld",
            flex: 1,
          }}
        >
          <Box className={boxStyles}>
            <Heading css={{ marginBottom: "ld" }} level={2}>
              <Trans>Contact Details</Trans>
            </Heading>
            <Form
              className={css({ display: "flex", flexDirection: "column", gap: "ld" })}
              method="post"
            >
              <InputField
                defaultValue={userDocument!.firstName}
                label={_(msg`First Name`)}
                name="firstName"
              />
              <InputField
                defaultValue={userDocument!.lastName}
                label={_(msg`Last Name`)}
                name="lastName"
              />
              <InputField
                defaultValue={userDocument!.email}
                disabled
                label={_(msg`Email`)}
                name="email"
              />
              <InputField
                defaultValue={userDocument!.organization}
                disabled
                label={_(msg`Organization`)}
                name="organization"
              />

              <Button
                className={ActionButtonStyles}
                disabled={isSubmitting}
                name="intent"
                type="submit"
                value="updateProfile"
                variant="primary"
              >
                <Trans>Save</Trans>
              </Button>
            </Form>
          </Box>
          <Box className={boxStyles}>
            <Heading level={2}>
              <Trans>Password</Trans>
            </Heading>
            <p>
              <Trans>You will receive an email to change your password.</Trans>
            </p>
            <Form method="post">
              <Button
                className={ActionButtonStyles}
                disabled={isSubmitting}
                name="intent"
                type="submit"
                value="sendResetLink"
                variant="primary"
              >
                <Trans>Send reset link</Trans>
              </Button>
            </Form>
          </Box>
          <Box className={boxStyles}>
            <Heading level={2}>
              <Trans>Delete account</Trans>
            </Heading>
            <p>
              <Trans>Revoke access to doowii and clear all data stored for your account.</Trans>
            </p>
            <Form className={css({ marginTop: "ld" })} method="post">
              <DeleteAccountDialog onConfirm={handleDeleteAccount} />
            </Form>
          </Box>
        </Box>
      </PageLayout>
    </>
  );
};

export { Settings };
