import { PATHS } from "@constants/Paths";
import { faArrowUpRightFromSquare, faLightbulbOn } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "@lingui/macro";
import { css } from "@styled-system/css";
import { Flex } from "@styled-system/jsx";
import {
  Card,
  Dialog,
  DialogBody,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Heading,
  Label,
} from "doowii-ui";
import { useId, useState } from "react";
import { Link } from "react-router-dom";

import { DoowiiCard } from "./DoowiiCard";
import { IntroCard } from "./IntroCard";
import { SampleCard } from "./SampleCard";
import { TipsCard } from "./TipsCard";
import { VariablesCard } from "./VariablesCard";
import { DataProviderCard } from "./DataProviderCard";

const LearnMoreSidePanel = () => {
  const id = useId();
  const [open, setOpen] = useState(false);

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <button
          aria-labelledby={`tips-modal-${id}`}
          className={css({ cursor: "pointer" })}
          onClick={() => setOpen(true)}
          type="button"
        >
          <Label
            className={css({
              display: "flex",
              gap: "sm",
              textAlign: "center",
              cursor: "pointer",
              fontSize: "lg",
              fontWeight: "bold",
            })}
            id={`learn-more-${id}`}
            level={2}
            variant="constrast"
          >
            <FontAwesomeIcon icon={faLightbulbOn} />
            <Trans>This demo is using the IPEDS dataset - Learn More</Trans>
          </Label>
        </button>
      </DialogTrigger>
      <DialogContent variant="slideRight">
        <DialogHeader>
          <DialogTitle>
            <Trans>Learn more about IPEDS dataset</Trans>
          </DialogTitle>
        </DialogHeader>

        <DialogBody>
          <Flex direction="column" gap="xl">
            <DoowiiCard />

            <IntroCard />
            <DataProviderCard />
            <VariablesCard />
            <TipsCard />
            <SampleCard />
            <Link to={PATHS.DATA_SCHEMA}>
              <Card>
                <Flex align="center" justify="space-between" w="100%">
                  <Heading level={3}>
                    <Trans>Data schema of the IPEDS dataset </Trans>
                  </Heading>

                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                </Flex>
              </Card>
            </Link>
          </Flex>
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};

export { LearnMoreSidePanel };
