import {
  faArrowUpRightFromSquare,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "@lingui/macro";
import * as Collapsible from "@radix-ui/react-collapsible";
import { css } from "@styled-system/css";
import { Box, Flex } from "@styled-system/jsx";
import { Card, Heading, Separator, Text } from "doowii-ui";
import { useState } from "react";

import { variablesData } from "./variablesData";

const VariablesCard = () => {
  const [open, setOpen] = useState(false);

  return (
    <Card>
      <Collapsible.Root className={css({ w: "100%" })} onOpenChange={setOpen} open={open}>
        <Collapsible.Trigger className={css({ w: "100%", cursor: "pointer" })}>
          <Flex align="center" justify="space-between">
            <Heading level={3}>
              <Trans>Commonly used variables in IPEDS</Trans>
            </Heading>
            <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} size="lg" />
          </Flex>
        </Collapsible.Trigger>

        <Collapsible.Content>
          <Separator className={css({ my: "4" })} colorful={false} />
          <Flex align="center" direction="column" gap="xl">
            {Object.entries(variablesData).map(([category, metrics]) => (
              <Box key={category}>
                <Heading level={3}>{category}</Heading>
                {Object.entries(metrics).map(([metricKey, metricDescription]) => (
                  <Text className={css({ my: "2" })} key={metricKey}>
                    <strong>{metricKey}: </strong>
                    <br />
                    {metricDescription}
                  </Text>
                ))}
              </Box>
            ))}
          </Flex>
          <Separator className={css({ my: "4" })} colorful={false} />

          <Flex align="center" justify="space-between" w="100%">
            <a
              href="https://nces.ed.gov/ipeds/datacenter/SelectVariables.aspx?stepId=1&sid=fabb7118-a9f0-42c6-b1bd-ad0b36b589cd&rtid=1"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Heading level={3}>
                <Trans>Link to the IPEDS variables site</Trans>
              </Heading>
            </a>
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </Flex>
        </Collapsible.Content>
      </Collapsible.Root>
    </Card>
  );
};

export { VariablesCard };
