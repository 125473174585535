import { PageHeader } from "@components/PageHeader";
import { CreatePinboardDialog } from "@components/PinboardCard";
import PinboardCardContainer from "@components/PinboardCard/components/PinboardCardContainer";
import { useChatData } from "@context/chat";
import { usePinboard } from "@context/pinboard";
import { faBars, faGrid2, faPlus, faRectangleHistory } from "@fortawesome/pro-regular-svg-icons";
import { useAuth } from "@hooks/useAuth";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { css } from "@styled-system/css";
import { Flex } from "@styled-system/jsx";
import { Button, PageLayout, SearchInput, Tooltip } from "doowii-ui";
import React, { useEffect, useState } from "react";

import { Doowii } from "../../search/Doowii";

export type PinboardsView = "grid" | "list";

interface ViewOptionsProps {
  view: PinboardsView;
  setView: React.Dispatch<React.SetStateAction<PinboardsView>>;
}
const ViewOptions: React.FC<ViewOptionsProps> = ({ view, setView }) => {
  const { _ } = useLingui();

  return (
    <Flex css={{ gap: "md" }}>
      <Tooltip content={_(msg`List view`)}>
        <Button
          aria-label={_(msg`Use list view`)}
          className={css({ bg: view === "list" ? "base.blueBrandTertiary!" : "" })}
          iconOnly={faBars}
          onClick={() => setView("list")}
          variant="dropdown"
        />
      </Tooltip>
      <Tooltip content={_(msg`Grid view`)}>
        <Button
          aria-label={_(msg`Use grid view`)}
          className={css({ bg: view === "grid" ? "base.blueBrandTertiary!" : "" })}
          iconOnly={faGrid2}
          onClick={() => setView("grid")}
          variant="dropdown"
        />
      </Tooltip>
    </Flex>
  );
};

const Pinboards = () => {
  const { _, i18n } = useLingui();
  const { boards } = usePinboard();
  const {
    setLoading,
    setAllResults,
    setAnswer,
    setStreamLoading,
    newChat,
    currentThread,
    allResults,
    threads,
  } = useChatData();
  const { userDocument } = useAuth();
  const [isCreatePinboardDialogOpen, setIsCreatePinboardDialogOpen] = useState(false);
  const [view, setView] = useState<PinboardsView>("grid");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [triggerId, setTriggerId] = useState("");

  const handleCreatePinboard = (id: string) => {
    setTriggerId(id);
    setIsCreatePinboardDialogOpen(true);
  };

  useEffect(() => {
    newChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doowii = new Doowii(
    i18n,
    setLoading,
    setAllResults,
    setAnswer,
    setStreamLoading,
    userDocument,
    currentThread,
    allResults,
    threads
  );

  return (
    <>
      <PageHeader description={_(msg`Pinboards page`)} title={_(msg`Doowii - Pinboards`)} />
      <PageLayout
        action={
          <Flex css={{ gap: "md" }}>
            <SearchInput
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={_(msg`Search pinboards`)}
              value={searchQuery}
              variant="standard"
            />

            <ViewOptions setView={setView} view={view} />

            <Button
              aria-label={_(msg`Add new Pinboard`)}
              iconLeft={faPlus}
              id="add-pinboard"
              onClick={(e) => handleCreatePinboard(e.currentTarget.id)}
            >
              <Trans>Add</Trans>
            </Button>
          </Flex>
        }
        emptyProps={{
          title: _(msg`It’s looking a bit empty here`),
          description: _(msg`You can create new pinboards and save your chats to them`),
          buttonText: _(msg`Create pinboard`),
          icon: faRectangleHistory,
          onClick: (e) => handleCreatePinboard(e.currentTarget.id),
        }}
        isEmpty={boards.size < 1}
        title={_(msg`Pinboards`)}
      >
        <PinboardCardContainer doowii={doowii} searchQuery={searchQuery} view={view} />
      </PageLayout>
      <CreatePinboardDialog
        isOpen={isCreatePinboardDialogOpen}
        setIsOpen={setIsCreatePinboardDialogOpen}
        triggerId={triggerId}
      />
    </>
  );
};

export { Pinboards };
