import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@components/Table";
import { Skeleton } from "doowii-ui";

const DataTableSkeleton = () => (
  <Table>
    <TableHeader>
      <TableRow>
        {[...Array(5)].map((_, index) => (
          <TableHead key={`header-${index}`}>
            <Skeleton height="14px" maxWidth="720px" w="full" />
          </TableHead>
        ))}
      </TableRow>
    </TableHeader>
    <TableBody>
      {[...Array(10)].map((_, rowIndex) => (
        <TableRow key={`row-${rowIndex}`}>
          {[...Array(5)].map((_, cellIndex) => (
            <TableCell key={`cell-${rowIndex}-${cellIndex}`}>
              <Skeleton height="14px" maxWidth="720px" w="full" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export { DataTableSkeleton };
