import { css } from "@styled-system/css";
import React from "react";

interface FaSortProps {
  isSorted: false | "asc" | "desc";
}

export const FaSort: React.FC<FaSortProps> = ({ isSorted }) => (
  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path
      className={css({
        fill: isSorted === "desc" ? "base.blueBrandPrimary" : "base.gray",
      })}
      d="M12.4182 20.2254C11.9106 20.733 11.0862 20.733 10.5786 20.2254L5.38081 15.0276C5.00722 14.654 4.89758 14.0976 5.10062 13.6103C5.30366 13.123 5.77471 12.8063 6.30262 12.8063H16.6983C17.2221 12.8063 17.6972 13.123 17.9003 13.6103C18.1033 14.0976 17.9896 14.654 17.6201 15.0276L12.4223 20.2254H12.4182Z"
      fillOpacity="0.59"
      id="arrow-down"
    />

    <path
      className={css({
        fill: isSorted === "asc" ? "base.blueBrandPrimary" : "base.gray",
      })}
      d="M12.4209 3.38488L17.6203 8.58434C17.994 8.95805 18.1037 9.51455 17.9006 10.002C17.6975 10.4895 17.2263 10.8063 16.6982 10.8063L6.30338 10.8022C5.77937 10.8022 5.30411 10.4854 5.101 9.99794C4.8979 9.51049 5.01164 8.95399 5.38129 8.58028L10.5807 3.38082C11.0885 2.87306 11.9131 2.87306 12.4209 3.38082V3.38488Z"
      fillOpacity="0.59"
      id="arrow-up"
    />
  </svg>
);
