import { faCode } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "@lingui/macro";
import { css } from "@styled-system/css";
import { Flex } from "@styled-system/jsx";
import { Logo, Text } from "doowii-ui";

const EmptyTableOverlay = () => (
  <Flex
    alignItems="center"
    direction="column"
    gap="10"
    height="100%"
    justifyItems="center"
    padding="40"
    pt="20"
  >
    <Logo full={false} />

    <Text level={3}>
      <Trans>
        Based on your question, we have generated the SQL query for you. Please access it by
        selecting the{" "}
        <FontAwesomeIcon className={css({ padding: "4px 4px 0 4px " })} icon={faCode} /> . However,
        please note that there is no data available to answer your question!
      </Trans>
    </Text>
  </Flex>
);

export { EmptyTableOverlay };
