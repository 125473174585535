import { PageHeader } from "@components/PageHeader";
import InviteUserDialog from "@components/UsersTable/InviteUserDialog";
import UsersTable from "@components/UsersTable/UsersTable";
import { useOrgUsers } from "@context/users";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Box, Flex } from "@styled-system/jsx";
import { Button, PageLayout, SearchInput } from "doowii-ui";
import { useLayoutEffect, useState } from "react";

const UserManagement = () => {
  const { _ } = useLingui();

  const { clearUsersData, fetchUsersInOrganization, organizationUsers, fetchingOrganizationUsers } =
    useOrgUsers();
  const [search, setSearch] = useState("");
  const [inviteModalOpen, setInviteModalOpen] = useState(false);

  const handleInvite = () => {
    setInviteModalOpen(true);
  };

  useLayoutEffect(() => {
    const fetch = () => {
      fetchUsersInOrganization();
    };

    fetch();

    return () => {
      clearUsersData();
    };
  }, [fetchUsersInOrganization, clearUsersData]);

  return (
    <>
      <PageHeader
        description={_(msg`Manage users in your organization`)}
        title={_(msg`Doowii - User managment`)}
      />

      <PageLayout
        action={
          <Flex css={{ gap: "sm" }}>
            <SearchInput
              aria-label={_(msg`Search users`)}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={_(msg`Search users`)}
              variant="standard"
            />
            <Button aria-label={_(msg`Invite new user`)} onClick={handleInvite}>
              {_(msg`Invite`)}
            </Button>
          </Flex>
        }
        emptyProps={{
          title: _(msg`No users found`),
          description: _(msg`No users found, try inviting some users to your organization.`),
          icon: faUser,
          buttonText: _(msg`Invite users`),
          onClick: handleInvite,
        }}
        isEmpty={organizationUsers.length < 1}
        loading={fetchingOrganizationUsers}
        title={_(msg`Manage users`)}
      >
        <Box css={{ w: "full" }}>
          <UsersTable search={search} />
        </Box>
      </PageLayout>
      {inviteModalOpen ? (
        <InviteUserDialog isOpen={inviteModalOpen} setIsOpen={setInviteModalOpen} />
      ) : null}
    </>
  );
};

export { UserManagement };
