import { HighchartsWithAccessibility, sharedChartOptions } from '@components/Charts/utils';

// Default Pie Chart Options
export const defaultPieChartOptions = (
  height: number,
  width: number,
): HighchartsWithAccessibility.Options => ({
  ...sharedChartOptions,
  chart: {
    ...sharedChartOptions.chart,
    type: 'pie',
    height,
    width,
    plotBackgroundColor: undefined,
    plotBorderWidth: undefined,
    plotShadow: false,
    style: {
      fontFamily: 'inherit',
    },
  },
  title: {
    text: "",
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    enabled: true,
  },
  accessibility: {
    point: {
      valueSuffix: '%',
    },
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
      },
      showInLegend: true,
    },
  },
  // @ts-ignore
  series: "{{series}}", // Data will be injected dynamically
  credits: {
    enabled: false,
  },
  legend: {
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'middle',
    floating: false,
    labelFormatter(this): string {
      const point = this as Highcharts.Point;
      return `${point.name}: ${point.y?.toFixed(1)}`;
    },
  },
});

// Default Bar Chart Options
export const defaultBarChartOptions = (
  height: number,
  width: number,
): HighchartsWithAccessibility.Options => ({
  ...sharedChartOptions,
  chart: {
    ...sharedChartOptions.chart,
    type: 'bar',
    height,
    width,
    style: {
      fontFamily: 'inherit',
    },
  },
  title: {
    text: "",
  },
  xAxis: {
    // @ts-ignore
    categories: "{{categories}}", // Will be injected dynamically
    title: {
      text: '', // Return the x-axis title
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: "Values",
    },
    crosshair: true,
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: false,
      },
    },
    series: {
      stacking: undefined,
    },
  },
  // @ts-ignore
  series: "{{series}}", 
  credits: {
    enabled: false,
  },
  tooltip: {
    enabled: true,
  },
  legend: {
    layout: 'horizontal',
    align: 'center',
    verticalAlign: 'bottom',
    floating: false,
  },
});

// Default Line Chart Options
export const defaultLineChartOptions = (
  height: number,
  width: number,
): HighchartsWithAccessibility.Options => ({
  ...sharedChartOptions,
  chart: {
    ...sharedChartOptions.chart,
    type: 'line',
    height,
    width,
    zooming: {
      type: 'x',
    },
    style: {
      fontFamily: 'inherit',
    },
  },
  title: {
    text: "",
  },
  xAxis: {
    // @ts-ignore
    categories: "{{categories}}", // Will be injected dynamically
    title: {
      text: '', // TODO: Return the column name
    },
    crosshair: true,
  },
  yAxis: {
    title: {
      text: "Values",
    },
  },
  //@ts-ignore
  series: "{{series}}", // Data will be injected dynamically
  credits: {
    enabled: false,
  },
  legend: {
    layout: 'horizontal',
    align: 'center',
    verticalAlign: 'bottom',
    floating: false,
  },
  tooltip: {
    enabled: true,
  },
  plotOptions: {
    line: {
      dataLabels: {
        enabled: false,
      },
      enableMouseTracking: true,
    },
  },
});

// Default Scatter Chart Options
export const defaultScatterChartOptions = (
  height: number,
  width: number,
): HighchartsWithAccessibility.Options => ({
  ...sharedChartOptions,
  chart: {
    ...sharedChartOptions.chart,
    type: 'scatter',
    height,
    width,
    zooming: {
      type: 'xy',
    },
    style: {
      fontFamily: 'inherit',
    },
  },
  title: {
    text: "",
  },
  xAxis: {
    title: {
      text: "{{xColumn}}", // Will be set based on data
    },
    crosshair: true,
  },
  yAxis: {
    title: {
      text: "{{yColumn}}", // Will be set based on data
    },
    crosshair: true,
  },
  legend: {
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'top',
    floating: true,
  },
  // @ts-ignore
  series: "{{series}}", // Data will be injected dynamically
  credits: {
    enabled: false,
  },
  tooltip: {
    headerFormat: '<b>{series.name}</b><br>',
    pointFormat: `${"{{xColumn}}"}: {point.x}<br>${"{{yColumn}}"}: {point.y}`, // Will be set based on data
    enabled: true,
  },
});
