import React from "react";
import { Box, Flex } from "@styled-system/jsx";

interface ChatAreaProps {
  children: React.ReactNode;
}

const ApolloChatArea: React.FC<ChatAreaProps> = ({ children }) => {
  return (
    <Box
      bg="white"
      border="1px solid"
      borderColor="gray.200"
      rounded="md"
      height="100%"
      p="md"
      overflowY="auto"
    >
      <Flex direction="column" gap="sm">
        {children}
      </Flex>
    </Box>
  );
};

export { ApolloChatArea };
