import { useModalClose } from "@hooks/useModalClose";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { sendContactRequest } from "@services/webserver/emails";
import { css } from "@styled-system/css";
import { verifyEmailDomain } from "@utils/verifyEmailDomain";
import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  InputField,
  Textarea,
} from "doowii-ui";
import { useState } from "react";

interface Props {
  isOpen: boolean;
  triggerId: string;
  setIsOpen: (isOpen: boolean) => void;
}
const ContactMeDialog: React.FC<Props> = ({ isOpen, setIsOpen, triggerId }) => {
  useModalClose(isOpen, triggerId);
  const { _ } = useLingui();

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [schoolName, setSchoolName] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const handleSend = async () => {
    setLoading(true);
    if (email.trim().length < 1) {
      setErrorMessage(_(msg`Email is required`));
    } else if (!(await verifyEmailDomain(email))) {
      setErrorMessage(_(msg`Please use a .edu, .org or .gov email address`));
    } else {
      await sendContactRequest({
        email,
        school_name: schoolName,
        message,
      });

      setIsOpen(false);
    }

    setLoading(false);
  };

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <DialogContent aria-describedby={undefined} onClick={(e) => e.stopPropagation()}>
        <DialogHeader>
          <DialogTitle>
            <Trans>Send Contact Request</Trans>
          </DialogTitle>
          <DialogDescription>
            <Trans>Do you want to use doowii with your own data?</Trans>
          </DialogDescription>
        </DialogHeader>
        <DialogBody className={css({ display: "flex", flexDirection: "column", gap: "ld" })}>
          <InputField
            disabled={loading}
            errorText={errorMessage}
            label={_(msg`Your School Email`)}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={_(msg`Enter school email`)}
            type="text"
            value={email}
          />
          <InputField
            disabled={loading}
            label={_(msg`Your School Name`)}
            onChange={(e) => setSchoolName(e.target.value)}
            placeholder={_(msg`Enter school name`)}
            type="text"
            value={schoolName}
          />
          <Textarea
            label={_(msg`Optional Message`)}
            onChange={(e) => setMessage(e.target.value)}
            placeholder={_(
              msg`Let us know more about your school and what you'd like to learn about Doowii`
            )}
            value={message}
          />
        </DialogBody>
        <DialogFooter className={css({ borderTop: "none", p: "xl2", gap: "sm" })}>
          <Button loading={loading} onClick={handleSend} size="large" variant="primary">
            <Trans>Send contact request</Trans>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export { ContactMeDialog };
