import { UserRoles } from "@doowii-types/auth";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { css } from "@styled-system/css";
import { Box } from "@styled-system/jsx";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Text } from "doowii-ui";

type UserRole = (typeof UserRoles)[keyof typeof UserRoles];

interface RoleSelectorProps {
  selectedRole: UserRole;
  setSelectedRole: React.Dispatch<React.SetStateAction<UserRole>>;
}

const RoleSelector: React.FC<RoleSelectorProps> = ({ selectedRole, setSelectedRole }) => {
  const { _ } = useLingui();
  const handleRoleChange = (value: string) => {
    setSelectedRole(value as UserRole);
  };
  return (
    <Box>
      <Text level={3}>{_(msg`Role`)}</Text>
      <Select name="role" onValueChange={handleRoleChange} value={selectedRole}>
        <SelectTrigger>
          <SelectValue id="role" name="role" placeholder={_(msg`Select role`)} />
        </SelectTrigger>
        <SelectContent className={css({ width: "400px" })}>
          <SelectItem value={UserRoles.Administrator}>{_(msg`Administrator`)}</SelectItem>
          <SelectItem value={UserRoles.Instructor}>{_(msg`Instructor`)}</SelectItem>
          <SelectItem value={UserRoles.Researcher}>{_(msg`Researcher`)}</SelectItem>
          <SelectItem value={UserRoles.ITProfessional}>{_(msg`IT Professional`)}</SelectItem>
          <SelectItem value={UserRoles.Other}>{_(msg`Other`)}</SelectItem>
        </SelectContent>
      </Select>
    </Box>
  );
};

export { RoleSelector };
