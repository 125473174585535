import { PATHS } from "@constants/Paths";
import { Navigate } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";

const Redirector = () => {
  const { authUser } = useAuth();
  return <Navigate to={`/${PATHS.USERS}/${authUser?.uid}/${PATHS.HOME}`} />;
};

export { Redirector };
