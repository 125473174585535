import { ParentDocTypeEnum } from "@api/retriever.i";
import { APOLLO_V2 } from "@constants/features";
import { Result } from "@doowii-types/chat";
import { Pin } from "@doowii-types/pinboard";
import { DataVizType, DataVizTypes } from "@doowii-types/viz";
import {
  faChartLine,
  faChartPie,
  faChartScatter,
  faChartSimple,
  faTableRows,
  faSparkles,
} from "@fortawesome/pro-regular-svg-icons";
import { useAuth } from "@hooks/useAuth";
import { useChartConfig } from "@hooks/useChartConfig";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import * as Tabs from "@radix-ui/react-tabs";
import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";
import { capitalizeFirstLetter } from "@utils/capitalizeFirstLetter";
import { Button, Tooltip } from "doowii-ui";
import { useId } from "react";
import { getAvailableCharts } from "./utils";

interface DataNavigatorProps {
  currentResult: Result | Pin;
  parentDocId: string;
  parentDocType: ParentDocTypeEnum;
  setIsApolloDialogOpen: (open: boolean) => void;
}

const dataVizIcons = {
  [DataVizTypes.TABLE]: faTableRows,
  [DataVizTypes.BAR_CHART]: faChartSimple,
  [DataVizTypes.PIE_CHART]: faChartPie,
  [DataVizTypes.LINE_CHART]: faChartLine,
  [DataVizTypes.SCATTER_CHART]: faChartScatter,
};

const DataNavigator = ({
  currentResult,
  parentDocId,
  parentDocType,
  setIsApolloDialogOpen,
}: DataNavigatorProps) => {
  const id = useId();
  const { _ } = useLingui();
  const { chartConfig, updateConfigState, resetTemporaryState } = useChartConfig({
    initialConfig: currentResult.chartConfig,
    chatId: currentResult.id,
    parentId: parentDocId,
    parentType: parentDocType,
  });

  const availableCharts = getAvailableCharts(chartConfig.column_types, chartConfig.columns);
  const { featureFlags } = useAuth();

  const dataVizLabels = {
    [DataVizTypes.TABLE]: _(msg`Table view`),
    [DataVizTypes.BAR_CHART]: _(msg`Bar Chart view`),
    [DataVizTypes.PIE_CHART]: _(msg`Pie Chart view`),
    [DataVizTypes.LINE_CHART]: _(msg`Line Chart view`),
    [DataVizTypes.SCATTER_CHART]: _(msg`Scatter Chart view`),
  };

  const openApolloDialog = () => {
    setIsApolloDialogOpen(true);
    resetTemporaryState();
  };

  return (
    <Tabs.Root
      value={chartConfig.suggestion}
      // @ts-ignore
      onValueChange={(value) => updateConfigState({ ...chartConfig, suggestion: value })}
    >
      <Tabs.List className={flex({ gap: "sm" })}>
        {Object.entries(dataVizIcons).map(([type, icon]) => (
          <Tabs.Trigger
            aria-label={dataVizLabels[type as keyof typeof dataVizLabels]}
            asChild
            disabled={
              !availableCharts.includes(type as DataVizType)
                ? type !== chartConfig.suggestion
                : false
            }
            id={`${id}-${type}`}
            key={type}
            value={type}
          >
            <Tooltip content={_(msg` ${capitalizeFirstLetter(type.toLowerCase())} chart`)}>
              <Button
                aria-describedby={`${id}-${type}`}
                className={css({
                  '&[data-state="active"]': {
                    backgroundColor: "base.blueBrandSecondary",
                  },
                })}
                iconOnly={icon}
                size="small"
                variant="dropdown"
              />
            </Tooltip>
          </Tabs.Trigger>
        ))}
        {featureFlags.includes(APOLLO_V2) && (
          <Tooltip content={_(msg`Chart Wizard (Beta)`)}>
            <Button
              aria-describedby={"chart-modification"}
              iconOnly={faSparkles}
              size="small"
              variant="dropdown"
              onClick={openApolloDialog}
              className={css({
                borderColor: "#F6CD3C",
                _hover: {
                  backgroundColor: "#fcf4d7",
                },
              })}
            />
          </Tooltip>
        )}
      </Tabs.List>
    </Tabs.Root>
  );
};
export { DataNavigator };
