import { css } from "@styled-system/css";
import { Heading } from "doowii-ui";
import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

interface MarkdownTextProps {
  markdownText: string;
}

interface CustomHeadingProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}

const CustomHeading: React.FC<CustomHeadingProps> = ({ children, ...props }) => (
  <div
    {...props}
    className={css({
      marginTop: children === "Methodology" ? "4" : "0",
    })}
  >
    <Heading level={3}>{children}</Heading>
  </div>
);

const MarkdownText: React.FC<MarkdownTextProps> = ({ markdownText }) => {
  if (!markdownText || typeof markdownText !== "string") {
    return null;
  }

  return (
    <ReactMarkdown
      components={{
        ul: ({ children }) => (
          <ul
            className={css({
              listStyle: "disc",
              padding: "revert",
            })}
          >
            {children}
          </ul>
        ),
        h2: ({ ...props }) => <CustomHeading {...props} />,
        h3: ({ ...props }) => <CustomHeading {...props} />,
      }}
      rehypePlugins={[rehypeRaw]}
    >
      {markdownText}
    </ReactMarkdown>
  );
};

export { MarkdownText };
