/* eslint-disable lingui/no-unlocalized-strings */
import { css, cx } from "@styled-system/css";
import * as React from "react";

const Table = React.forwardRef<HTMLTableElement, React.HTMLAttributes<HTMLTableElement>>(
  ({ className, ...props }, ref) => (
    <div className={css({ position: "relative", width: "full" })}>
      <table
        className={cx(
          css({
            width: "full",
            captionSide: "bottom",
            textStyle: "label_14",
            color: "token(colors.base.darkBlue)",
          }),
          className
        )}
        ref={ref}
        {...props}
      />
    </div>
  )
);
Table.displayName = "Table";

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead
    className={cx(
      css({
        backgroundColor: "token(colors.base.lightherGray)",
        position: "sticky",
        top: 0,
        zIndex: 1,
      }),
      className
    )}
    ref={ref}
    {...props}
  />
));
TableHeader.displayName = "TableHeader";

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    className={cx(
      css({
        py: "3",
        px: "4",
        textAlign: "left",
        verticalAlign: "middle",
        textStyle: "label_14",
        color: "token(colors.base.darkBlue)",
        borderLeft: "1px solid",
        borderRight: "1px solid",
        borderColor: "base.lightGray",
        _hover: { backgroundColor: "token(colors.base.lightherGray)" },
      }),
      className
    )}
    ref={ref}
    {...props}
  />
));
TableHead.displayName = "TableHead";

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    className={cx(css({ "&tr _last": { borderBottomWidth: "0px" } }), className)}
    ref={ref}
    {...props}
  />
));
TableBody.displayName = "TableBody";

const TableRow = React.forwardRef<HTMLTableRowElement, React.HTMLAttributes<HTMLTableRowElement>>(
  ({ className, ...props }, ref) => (
    <tr
      className={cx(
        css({
          borderBottom: "1px solid token(colors.base.lightGray)",
          _hover: { backgroundColor: "token(colors.base.lightherGray)" },
        }),
        className
      )}
      ref={ref}
      {...props}
    />
  )
);
TableRow.displayName = "TableRow";

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    className={cx(
      css({
        px: "4",
        py: "3",
        textAlign: "left",
        verticalAlign: "middle",
        textStyle: "label_14",
        color: "token(colors.base.darkBlue)",
      }),
      className
    )}
    ref={ref}
    {...props}
  />
));
TableCell.displayName = "TableCell";

export { Table, TableBody, TableCell, TableHead, TableHeader, TableRow };
