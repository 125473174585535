import { css } from "@styled-system/css";
import { Box } from "@styled-system/jsx";
import { Skeleton } from "doowii-ui";

const ChatExplanationSkeleton = () => {
  return (
    <>
      <Skeleton width="50%" height="2.5rem" mb="2" />
      <Skeleton width="100%" height="6rem" mb="2" />
      <Box className={css({ mt: "4" })}>
        <Skeleton width="50%" height="2.5rem" mb="2" />
        <Skeleton width="60%" height="1.5rem" mb="2" />
        <Skeleton width="80%" height="1.5rem" mb="2" />
        <Skeleton width="90%" height="1.5rem" mb="2" />
        <Skeleton width="70%" height="1.5rem" mb="2" />
        <Skeleton width="80%" height="1.5rem" mb="2" />
        <Skeleton width="90%" height="1.5rem" mb="2" />
        <Skeleton width="40%" height="1rem" mb="2" />
        <Skeleton width="70%" height="1.5rem" mb="2" />
        <Skeleton width="80%" height="1.5rem" mb="2" />
      </Box>
    </>
  );
};

export { ChatExplanationSkeleton };
