import { t } from "@lingui/macro";
import { signInWithFirebase, signInWithGoogle } from "@services/firebase";
import { ActionFunctionArgs, json } from "react-router-dom";

interface SignInActionResponse {
  errors?: { form?: string; email?: string; password?: string };
  fields?: { email?: string | null; password?: string | null };
  loadingUser?: boolean;
}

const signInAction = async ({
  request,
}: ActionFunctionArgs): Promise<SignInActionResponse | Response> => {
  const formData = await request.formData();
  const signInMethod = formData.get("signInMethod");

  try {
    if (signInMethod === "google") {
      await signInWithGoogle();
    } else {
      const email = formData.get("email") as string;
      const password = formData.get("password") as string;

      const errors: { email?: string; password?: string } = {};
      if (!email) {
        errors.email = t`Email is required`;
      }
      if (!password) {
        errors.password = t`Password is required`;
      }

      if (Object.keys(errors).length > 0) {
        return json({ errors, fields: { email, password } }, { status: 400 });
      }

      await signInWithFirebase(email, password);
    }
    return json({ loadingUser: true });
  } catch (error) {
    console.error(error);
    const errorMessage =
      signInMethod === "google" ? t`Google sign-in failed` : t`Invalid email or password`;
    return json(
      {
        errors: { email: errorMessage },
        fields: {
          email: formData.get("email"),
          password: formData.get("password"),
        },
      },
      { status: 401 }
    );
  }
};

export { signInAction, type SignInActionResponse };
