import { t } from "@lingui/macro";
import { sendPwdResetEmailWithFirebase } from "@services/firebase";
import { ActionFunctionArgs, json } from "react-router-dom";

interface ResetPasswordActionResponse {
  errors?: { email?: string };
  fields?: { email?: string | null };
  success?: boolean;
}

const resetPasswordAction = async ({
  request,
}: ActionFunctionArgs): Promise<ResetPasswordActionResponse | Response> => {
  const formData = await request.formData();
  const email = formData.get("email") as string;

  const errors: { email?: string } = {};
  if (!email) {
    errors.email = t`Email is required`;
  }

  if (Object.keys(errors).length > 0) {
    return json({ errors, fields: { email } }, { status: 400 });
  }

  try {
    await sendPwdResetEmailWithFirebase(email);
    return json({
      fields: { email: t`If the email is registered, you will receive a reset link.` },
      success: true,
    });
  } catch (error) {
    console.error(error);
    return json(
      {
        errors: { email: (error as Error).message || t`Failed to send reset link` },
        fields: { email },
      },
      { status: 500 }
    );
  }
};

export { resetPasswordAction, type ResetPasswordActionResponse };
