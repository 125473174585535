import { PageHeader } from "@components/PageHeader";
import { SchemaIframe } from "@components/SchemaIfame";
import { PATHS } from "@constants/Paths";
import { faRectangleHistory } from "@fortawesome/pro-regular-svg-icons";
import { useAuth } from "@hooks/useAuth";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { fetchSchemaURL } from "@services/firebase";
import { PageLayout } from "doowii-ui";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const DataSchema: React.FC = () => {
  const { userDocument } = useAuth();
  const [schemaURL, setSchemaURL] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getSchema = async (orgId: string) => {
      try {
        setIsLoading(true);
        const response = await fetchSchemaURL(orgId);
        setSchemaURL(response);
      } catch (err) {
        console.error("Error fetching schema URL:", err);
      } finally {
        setIsLoading(false);
      }
    };
    if (userDocument?.organization) {
      void getSchema(userDocument.organization);
    }
  }, [userDocument]);
  const navigate = useNavigate();
  const { _ } = useLingui();
  return (
    <>
      <PageHeader
        description={_(msg`visualize data schema`)}
        title={_(msg`Doowii - Data schema`)}
      />

      <PageLayout
        emptyProps={{
          title: _(msg`It’s looking a bit empty here`),
          description: _(msg`Please contact your admin to set up the data schema`),
          buttonText: _(msg`Start a chat`),
          icon: faRectangleHistory,
          onClick: () => navigate(PATHS.ROOT),
        }}
        isEmpty={!schemaURL ? !isLoading : undefined}
        title={_(msg`Data Schema`)}
      >
        {schemaURL ? <SchemaIframe url={schemaURL} /> : null}
      </PageLayout>
    </>
  );
};

export { DataSchema };
