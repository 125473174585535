export const variablesData = {
  "Enrollment Data": {
    "Total Enrollment":
      "Total number of students enrolled in the institution (undergraduate and graduate).",
    "Full-Time vs. Part-Time Enrollment":
      "Breaks down the number of students attending full-time or part-time.",
    "Gender Distribution": "Percentage of enrolled students by gender.",
    "Race/Ethnicity Breakdown":
      "Enrollment percentages across racial/ethnic groups (e.g., White, Black, Hispanic, Asian, etc.).",
  },
  "Programs & Degrees": {
    "Degree Programs Offered":
      "Listing of degree programs offered at various levels (associate, bachelor’s, master’s, doctorate).",
    "Completions by Field of Study":
      "Number of degrees awarded by field of study (e.g., business, education, STEM).",
  },
  "Graduation Rates & Outcomes": {
    "Graduation Rate":
      "Percentage of students who complete their program within 150% of the normal time (e.g., 6 years for a 4-year program).",
    "Retention Rate": "Percentage of first-time students who return for their second year.",
    "Transfer-Out Rate": "Percentage of students transferring to other institutions.",
  },
  "Financial Information": {
    "Institutional Revenue":
      "Revenue breakdown by source (e.g., tuition, government grants, private donations).",
    Expenditures:
      "Institutional spending on instruction, research, public service, and student services.",
  },
};
