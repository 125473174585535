import { z } from "zod";

const envSchema = z.object({
  REACT_APP_SEQUALIZER_API_URL: z.string().url(),
  REACT_APP_ENV: z.string(),
  REACT_APP_PROJECT_ID: z.string(),
  REACT_APP_FIREBASE_CONFIG: z.string(), // stringified firebase config object
  REACT_APP_WEB_SERVER_URL: z.string().url(),
});

export const env = envSchema.parse(import.meta.env);
