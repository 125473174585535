import { PATHS } from "@constants/Paths";
import { t } from "@lingui/macro";
import { exportMarketingOptInUsers } from "@services/webserver/sheets";
import { ActionFunctionArgs, json, redirect } from "react-router-dom";

import { useBoundStore } from "../../stores/store";
import { UserDocument, UserRoles } from "../../types";

interface RegisterActionResponse {
  newUserDoc?: UserDocument;
  errors?: {
    form?: string;
    firstName?: string;
    lastName?: string;
    role?: string;
  };
  fields: {
    firstName: UserDocument["firstName"];
    lastName: UserDocument["lastName"];
    role?: UserDocument["role"];
  };
}

const registerAction = async ({
  request,
}: ActionFunctionArgs): Promise<RegisterActionResponse | Response> => {
  const { authUser } = useBoundStore.getState();
  if (!authUser) {
    return redirect(PATHS.SIGN_IN);
  }
  const formData = await request.formData();

  const firstName = formData.get("firstName");
  const lastName = formData.get("lastName");
  const role = formData.get("role") ?? UserRoles.Administrator;
  const otherRole = formData.get("otherRole") ?? UserRoles.Other;
  const marketingOptIn = formData.get("marketingOptIn") === "on";

  const errors: { firstName?: string; lastName?: string; role?: string } = {};
  if (!firstName) {
    errors.firstName = t`First name is required`;
  }
  if (!lastName) {
    errors.lastName = t`Last name is required`;
  }

  if (Object.keys(errors).length > 0) {
    return json({ errors, fields: { firstName, lastName, role } }, { status: 400 });
  }

  try {
    const userDetails = {
      firstName,
      lastName,
      role,
      otherRole,
      marketingOptIn,
    } as Partial<UserDocument>;

    const newUserDoc = await useBoundStore.getState().createNewUser(userDetails);

    const exportMarketingOptInUsersRequest = {
      name: `${String(firstName)} ${String(lastName)}`,
      email: authUser.email,
      role: String(formData.get("otherRole") ?? formData.get("role")),
      marketing_opt_in: marketingOptIn,
    };
    await exportMarketingOptInUsers(exportMarketingOptInUsersRequest);

    return json({ newUserDoc });
  } catch (error) {
    console.error(error);
    return json(
      {
        errors: { form: t`Failed to create user document` },
        fields: { firstName, lastName, role },
      },
      { status: 500 }
    );
  }
};

export { registerAction, type RegisterActionResponse };
