import { faBell } from "@fortawesome/pro-regular-svg-icons";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Button, Tooltip } from "doowii-ui";

const NotificationsButton = () => {
  const { _ } = useLingui();
  return (
    <Tooltip content={_(msg`No unread notifications`)}>
      <Button aria-label={_(msg`Open notification panel`)} iconOnly={faBell} variant="dropdown" />
    </Tooltip>
  );
};

export { NotificationsButton };
