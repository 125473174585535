import { components, operations } from "@doowii-types/WebserverInterfaces.gen";
import { AxiosResponse } from "axios";

import { post } from "./base";

type SendInvitationEmailRequest =
  components["schemas"]["Body_send_invitation_email_api_send_invitation_email__post"];

type SendInvitationEmailResponse =
  operations["send_invitation_email_api_send_invitation_email__post"]["responses"]["200"]["content"]["application/json"];

type ShareResultRequest = components["schemas"]["ShareResultRequest"];

type ShareResultResponse =
  operations["share_result_api_share_result__post"]["responses"]["200"]["content"]["application/json"];

export const sendInvitationEmail = async (
  email: string,
  invitedBy: string
): Promise<AxiosResponse<SendInvitationEmailResponse> | undefined> => {
  try {
    const request: SendInvitationEmailRequest = {
      invitation: {
        invited_email: email,
        invited_by: invitedBy,
      },
    };
    return await post("/send-invitation-email/", request);
  } catch (error) {
    console.error("Error sending email", error);
  }
};

export const shareResult = async (
  request: ShareResultRequest
): Promise<AxiosResponse<ShareResultResponse> | undefined> => {
  try {
    return await post("/share-result/", { share_request: request });
  } catch (error) {
    console.error("Error sharing result", error);
  }
};

type SendContactRequestRequest = components["schemas"]["SendContactRequest"];

type SendContactRequestResponse =
  operations["send_contact_request_api_send_contact_request__post"]["responses"]["200"]["content"]["application/json"];

export const sendContactRequest = async (
  request: SendContactRequestRequest
): Promise<AxiosResponse<SendContactRequestResponse> | undefined> => {
  try {
    return await post("/send-contact-request/", request);
  } catch (error) {
    console.error("Error sending contact request", error);
  }
};
