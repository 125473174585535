import { useOrgUsers } from "@context/users";
import { User } from "@context/users/users.i";
import { faTrash, faUser } from "@fortawesome/pro-regular-svg-icons";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Flex } from "@styled-system/jsx";
import { Button, CircularIcon, ColumnDef, Heading, SimpleTable } from "doowii-ui";
import { useState } from "react";

import DeleteUserDialog from "./DeleteUserDialog";

interface Props {
  search: string;
}

const NoUsersFound = () => (
  <Flex
    css={{
      flexDirection: "column",
      gap: "md",
      alignItems: "center",
      justifyContent: "center",
      p: "xl2",
      h: 400,
    }}
  >
    <CircularIcon icon={faUser} />
    <Heading level={2}>
      <Trans>No users found</Trans>
    </Heading>
  </Flex>
);

const UsersTable: React.FC<Props> = ({ search }) => {
  const { _ } = useLingui();
  const { organizationUsers } = useOrgUsers();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const columns: ColumnDef<User, unknown>[] = [
    {
      header: _(msg`Name`),
      accessorFn: (row) => `${row.firstName} ${row.lastName}`,
      id: "fullName",
      cell: (info) => info.getValue(),
      filterFn: "includesString",
    },
    {
      header: _(msg`Email`),
      accessorKey: "email",
      filterFn: "includesString",
    },
    {
      header: _(msg`Joined on`),
      accessorFn: (row) => row.registration.date,
    },
    {
      header: _(msg`Last logged in`),
      accessorKey: "LSO",
    },
    {
      id: "actions",
      header: _(msg`Actions`),
      isAction: true,
      enableHiding: false,
      cell: ({ row }) => (
        <Flex css={{ gap: "xl" }}>
          <Button
            aria-label={_(msg`Open delete user modal`)}
            iconOnly={faTrash}
            onClick={() => {
              setSelectedUser(row.original);
              setDeleteModalOpen(true);
            }}
            variant="dropdown"
          />
        </Flex>
      ),
    },
  ];

  return (
    <>
      <SimpleTable
        columns={columns}
        data={organizationUsers}
        emptyComponent={<NoUsersFound />}
        globalFilter={search}
      />
      {deleteModalOpen ? (
        <DeleteUserDialog
          isOpen={deleteModalOpen}
          selectedUser={selectedUser!}
          setIsOpen={() => setDeleteModalOpen(false)}
          setSelectedUser={setSelectedUser}
        />
      ) : null}
    </>
  );
};

export default UsersTable;
