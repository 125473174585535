import { Trans } from "@lingui/macro";
import { css } from "@styled-system/css";
import { Box } from "@styled-system/jsx";

const ChartErrorState = () => (
  <Box
    className={css({
      display: "flex",
      flexDirection: "column",
      marginTop: "xl6",
    })}
  >
    <Box
      className={css({
        color: "complementary.error",
        fontSize: "title_h2.fontSize",
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: "md",
      })}
    >
      <Trans>Sorry</Trans>
    </Box>
    <Box
      className={css({
        fontSize: "paragraph_16.fontSize",
        textAlign: "center",
      })}
    >
      <Trans>We could not generate the graph this time.</Trans>
      <br />
      <Trans>Please try the table view instead.</Trans>
    </Box>
  </Box>
);

export { ChartErrorState };
