import { useAuth } from "@hooks/useAuth";
import { useEffect } from "react";

const AuthInitializer = ({ children }: { children: React.ReactNode }) => {
  const { initializeAuthListener } = useAuth();

  useEffect(() => {
    if (initializeAuthListener) {
      initializeAuthListener();
    } else {
      console.warn("initializeAuthListener is undefined");
    }
  }, [initializeAuthListener]);

  return children;
};

export default AuthInitializer;
