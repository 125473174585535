import { HIGHER_ED } from "@constants/constants";
import { PATHS } from "@constants/Paths";
import { t } from "@lingui/macro";
import { signInWithGoogle, signUpWithFirebase } from "@services/firebase";
import { useBoundStore } from "@stores/store";
import { verifyEmailDomain } from "@utils/verifyEmailDomain";
import { ActionFunctionArgs, json, redirect } from "react-router-dom";

import { env } from "../../env";

interface SignUpActionResponse {
  errors?: {
    form?: string;
    email?: string;
    password?: string;
    confirmPassword?: string;
  };
  fields?: {
    email?: string | null;
    password?: string | null;
    confirmPassword?: string | null;
  };
  loadingUser?: boolean;
}

const signUpAction = async ({
  request,
}: ActionFunctionArgs): Promise<SignUpActionResponse | Response> => {
  const { authUser, featureFlags } = useBoundStore.getState();
  if (authUser) {
    return redirect(PATHS.ROOT);
  }
  const formData = await request.formData();
  const signUpMethod = formData.get("signUpMethod");

  try {
    if (signUpMethod === "google") {
      await signInWithGoogle();
    } else {
      const email = formData.get("email") as string;
      const password = formData.get("password") as string;
      const confirmPassword = formData.get("confirmPassword") as string;

      const errors: {
        email?: string;
        password?: string;
        confirmPassword?: string;
      } = {};
      if (!email) {
        errors.email = t`Email is required`;
      }

      if (env.REACT_APP_ENV === HIGHER_ED && !(await verifyEmailDomain(email))) {
        errors.email = t`Please use a .edu, .org or .gov email address`;
      }

      if (!password) {
        errors.password = t`Password is required`;
      }
      if (password !== confirmPassword) {
        errors.confirmPassword = t`Passwords do not match`;
      }

      if (Object.keys(errors).length > 0) {
        return json({ errors, fields: { email, password, confirmPassword } }, { status: 400 });
      }

      await signUpWithFirebase(email, password);
    }
    return json({ loadingUser: true });
  } catch (error: unknown) {
    console.error(error);
    const errorMessage =
      signUpMethod === "google" ? t`Google sign-up failed` : t`Failed to create account`;
    return json(
      {
        errors: { email: errorMessage },
        fields: {
          email: formData.get("email"),
          password: formData.get("password"),
          confirmPassword: formData.get("confirmPassword"),
        },
      },
      { status: 401 }
    );
  }
};

export { signUpAction, type SignUpActionResponse };
