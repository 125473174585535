import { PATH_PARAMS, PATHS } from "@constants/Paths";
import { useChatData } from "@context/chat";
import { usePinboard } from "@context/pinboard";
import { AuthLayout } from "@layouts/AuthLayout";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { DataSchema } from "@pages/DataSchema";
import { Onboarding } from "@pages/Onboarding";
import { useMemo } from "react";
import { createBrowserRouter, Outlet, Params } from "react-router-dom";

import { Thread } from "../pages/Threads/Thread/Thread";
import { Threads } from "../pages/Threads/Threads";
import { NotFoundPage } from "./404";
import { ErrorPage } from "./Error";
import { Home } from "./Home";
import { Pinboards } from "./Pinboards";
import { Pinboard } from "./Pinboards/Pinboard";
import { Redirector } from "./Redirector";
import { Register, registerAction } from "./Register";
import { ResetPassword, resetPasswordAction } from "./ResetPassword";
import { rootLoader } from "./rootLoader";
import { Settings, settingsAction } from "./Settings";
import { SignIn, signInAction } from "./SignIn";
import { signOutAction } from "./SignOut";
import { SignUp, signUpAction } from "./SignUp";
import { UserManagement } from "./UserManagement";

/*
Routes:

Must be authenticated:
    /settings
    /data-schema
    /user-management
    /sources

    current user must be able to view/edit :pinboardId (via loader)
        /user/:userId/pinboards
        /user/:userId/pinboards/:pinboardId

    current user must be able to view/edit :threadId (via loader)
        /user/:userId/threads
        /user/:userId/threads/:threadId 

Public:
    /sign-in
    /404
*/

// Loaders need to fetch current user at ever level currently:
// https://github.com/remix-run/react-router/discussions/9564

const useRouter = () => {
  const { _ } = useLingui();
  const { boards } = usePinboard();
  const { threads } = useChatData();

  return useMemo(
    () =>
      createBrowserRouter([
        {
          path: PATHS.ROOT,
          loader: rootLoader,
          element: <AuthLayout />,
          errorElement: <ErrorPage />,
          children: [
            {
              index: true,
              element: <Redirector />,
            },
            {
              path: PATHS.SETTINGS,
              element: <Settings />,
              action: settingsAction,
            },

            {
              path: PATHS.SIGN_OUT,
              action: signOutAction,
            },
            {
              path: PATHS.USER_MANAGEMENT,
              element: <UserManagement />, // TODO: create a user management page
            },
            {
              path: PATHS.DATA_SCHEMA,
              element: <DataSchema />, // TODO: create a data schema page
            },

            {
              path: PATHS.SOURCES,
              element: <div>Sources</div>, // TODO: create a sources page
            },
            {
              path: `${PATHS.USERS}/:${PATH_PARAMS.USER_ID}`,
              element: <Outlet />,
              children: [
                {
                  path: PATHS.HOME,
                  element: <Home />,
                },
                {
                  path: PATHS.PINBOARDS,
                  element: <Outlet />,
                  children: [
                    {
                      path: ``,
                      element: <Pinboards />,
                    },
                    {
                      path: `:${PATH_PARAMS.PINBOARD_ID}`,
                      element: <Pinboard />,
                      handle: {
                        crumbs: [
                          {
                            text: _(msg`Pinboards`),
                            link: (params: Readonly<Params>) =>
                              `/${PATHS.USERS}/${params.userId}/${PATHS.PINBOARDS}`,
                          },
                          {
                            dynamicText: (params: Readonly<Params>) =>
                              params.pinboardId ? boards.get(params.pinboardId)?.name : "Undefined",
                          },
                        ],
                      },
                    },
                  ],
                },
                {
                  path: PATHS.THREADS,
                  element: <Outlet />,
                  children: [
                    {
                      path: ``,
                      element: <Threads />,
                    },
                    {
                      path: `:${PATH_PARAMS.THREAD_ID}`,
                      element: <Thread />,
                      handle: {
                        crumbs: [
                          {
                            text: _(msg`Threads`),
                            link: (params: Readonly<Params>) =>
                              `/${PATHS.USERS}/${params.userId}/${PATHS.THREADS}`,
                          },
                          {
                            dynamicText: (params: Readonly<Params>) =>
                              threads.reduce(
                                (acc, thread) =>
                                  thread.id === params.threadId ? thread.title : acc,
                                "New Chat"
                              ),
                          },
                        ],
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: PATHS.RESET_PASSWORD,
          element: <ResetPassword />,
          errorElement: <ErrorPage />,
          action: resetPasswordAction,
        },
        {
          path: PATHS.SIGN_IN,
          element: <SignIn />,
          errorElement: <ErrorPage />,
          action: signInAction,
        },
        {
          path: PATHS.REGISTER,
          element: <Register />,
          errorElement: <ErrorPage />,
          action: registerAction,
        },
        {
          path: PATHS.SIGN_UP,
          element: <SignUp />,
          errorElement: <ErrorPage />,
          action: signUpAction,
        },
        {
          path: PATHS.ONBOARDING,
          element: <Onboarding />,
          // action: settingsAction,
        },
        {
          path: PATHS.PAGE_NOT_FOUND,
          element: <NotFoundPage />,
        },
      ]),
    [_, boards, threads]
  );
};

export default useRouter;
