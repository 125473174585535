import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { getAuth } from "firebase/auth";

import { env } from "../../env";

export const getCurrentUserAuthorization = (): Promise<string> => {
  const currentUser = getAuth().currentUser;
  if (currentUser) {
    return currentUser.getIdToken(true);
  } else {
    throw new Error("No user is logged in");
  }
};

const webServerUrl = env.REACT_APP_WEB_SERVER_URL;
const webServerApi: AxiosInstance = axios.create({
  baseURL: `${webServerUrl}/api`,
  headers: { "Content-Type": "application/json" },
});

const getAuthHeader = async (): Promise<AxiosRequestConfig> => {
  try {
    const token = await getCurrentUserAuthorization();
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  } catch (error) {
    throw new Error("Error getting the auth token");
  }
};

const get = async (path: string, params?: object) => {
  const authHeader = await getAuthHeader();
  return webServerApi.get(path, { ...authHeader, params });
};

const post = async (path: string, data: object) => {
  const authHeader = await getAuthHeader();
  return webServerApi.post(path, data, authHeader);
};

const put = async (path: string, data: object) => {
  const authHeader = await getAuthHeader();
  return webServerApi.put(path, data, authHeader);
};

const remove = async (path: string) => {
  const authHeader = await getAuthHeader();
  return webServerApi.delete(path, authHeader);
};

const postUnauthenticated = (path: string, data: object) => webServerApi.post(path, data);

export { get, post, postUnauthenticated, put, remove };
