import React from "react";
import { LogoAvatar } from "@components/ChatContainer";
import { UserAvatar } from "@components/UserAvatar";
import { Flex } from "@styled-system/jsx";
import { css } from "@doowii/design-system/css";
import { Text } from "doowii-ui";

interface ApolloConversationProps {
  conversation: any[];
}

const formatMessage = (message: string) => {
  // Split the message into sentences
  const sentences = message.match(/[^.!?]+[.!?]+[\])'"`’”]*|.+$/g);

  if (!sentences) {
    return [message];
  }

  // Group sentences into paragraphs of two sentences
  const paragraphs = [];
  for (let i = 0; i < sentences.length; i += 2) {
    const paragraph = sentences
      .slice(i, i + 2)
      .join(" ")
      .trim();
    paragraphs.push(paragraph);
  }

  return paragraphs;
};

const UserChatBubble = ({ message }: { message: string }) => {
  const paragraphs = formatMessage(message);

  return (
    <div
      className={css({
        display: "flex",
        alignItems: "flex-start",
        gap: "sm",
        marginBottom: "sm",
        flexDirection: "row",
      })}
    >
      <UserAvatar />
      <div
        className={css({
          backgroundColor: "{colors.background.bar}",
          borderRadius: "md",
          maxWidth: "80%",
        })}
      >
        {paragraphs.map((paragraph, index) => (
          <Text
            key={index}
            className={css({
              marginBottom: index !== paragraphs.length - 1 ? "md" : "0",
              padding: "md",
            })}
          >
            {paragraph}
          </Text>
        ))}
      </div>
    </div>
  );
};

const ApolloChatBubble = ({ message }: { message: string }) => {
  const paragraphs = formatMessage(message);

  return (
    <div
      className={css({
        display: "flex",
        alignItems: "flex-start",
        gap: "sm",
        marginBottom: "sm",
        flexDirection: "row-reverse",
      })}
    >
      <LogoAvatar />
      <div
        className={css({
          backgroundColor: "{colors.background.bar}",
          borderRadius: "md",
          maxWidth: "80%",
        })}
      >
        {paragraphs.map((paragraph, index) => (
          <Text
            key={index}
            className={css({
              marginBottom: index !== paragraphs.length - 1 ? "sm" : "0",
              padding: "md",
            })}
          >
            {paragraph}
          </Text>
        ))}
      </div>
    </div>
  );
};

const ApolloConversation: React.FC<ApolloConversationProps> = ({ conversation }) => {
  return (
    <Flex direction="column">
      {conversation.map((item, index) => {
        return item.author === "Doowii" ? (
          <ApolloChatBubble key={index} message={item.message} />
        ) : (
          <UserChatBubble key={index} message={item.message} />
        );
      })}
    </Flex>
  );
};

export { ApolloConversation };
