import { css } from "@styled-system/css";
import { SortingState } from "@tanstack/react-table";
import { Tooltip } from "doowii-ui";

import { FaSort } from "./FaSort";

interface SortingControlProps {
  isSorted: false | "asc" | "desc";
  id: string;

  setSorting: (sorting: SortingState) => void;
}

const SortingControl = ({ isSorted, id, setSorting }: SortingControlProps) => (
  <Tooltip
    content={
      isSorted === false ? "Sort ascending" : isSorted === "asc" ? "Sort descending" : "Remove sort"
    }
  >
    <button
      className={css({
        cursor: "pointer",
      })}
      onClick={() => {
        if (isSorted === false) {
          setSorting([
            {
              id,
              desc: false,
            },
          ]);
        } else if (isSorted === "asc") {
          setSorting([
            {
              id,
              desc: true,
            },
          ]);
        } else if (isSorted === "desc") {
          setSorting([]);
        }
      }}
      type="button"
    >
      <FaSort isSorted={isSorted} />
    </button>
  </Tooltip>
);

export { SortingControl };
