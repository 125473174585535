import { PageHeader } from "@components/PageHeader";
import { PATHS } from "@constants/Paths";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { css } from "@styled-system/css";
import { Box, Grid, VStack } from "@styled-system/jsx";
import { flex } from "@styled-system/patterns";
import { BackgroundBox, Button, Heading, Text } from "doowii-ui";
import { useNavigate } from "react-router-dom";

const Onboarding = () => {
  const { _ } = useLingui();
  const navigate = useNavigate();

  return (
    <>
      <PageHeader description={_(msg`Welcome to Doowii`)} title={_(msg`Doowii - Welcome`)} />

      <BackgroundBox
        containerProps={{ css: {} }}
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "100vh",
          padding: "xl6",
        }}
      >
        <Box
          className={flex({
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            flex: 1,
          })}
        >
          <Box mb={8} textAlign="center">
            <Heading level={1}>Welcome to doowii!</Heading>
            <Text level={3}>
              <Trans>
                Take a sneak peek on how you can use our product in your day-to-day life
              </Trans>
            </Text>
          </Box>

          <VStack gap="5" width="100%">
            <Grid
              css={{
                width: "80%",
                height: "25vh",
                gridTemplateColumns: "30% 70%",
                border: "1px solid token(colors.base.lightherGray)",
                bg: "base.white",
                gap: "0",
                rounded: "lg",
                shadow: "regular",
              }}
            >
              <Box p={6} textAlign="left">
                <Heading
                  css={{
                    color: "base.gray",
                  }}
                  level={1}
                >
                  01
                </Heading>
                <Heading level={3}>
                  <Trans>Search for a topic that you would like to learn more about</Trans>
                </Heading>
              </Box>

              <Box
                css={{
                  backgroundImage: "url(/onboarding_step_1.svg)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  roundedRight: "lg",
                }}
              />
            </Grid>

            <Grid
              css={{
                width: "80%",
                gridTemplateColumns: "1fr 1fr",
              }}
              gap={5}
            >
              <Grid
                columns={1}
                css={{
                  gridTemplateRows: "0.2fr 1fr",
                  gap: "0",
                  border: "1px solid token(colors.base.lightherGray)",
                  bg: "base.white",
                  rounded: "lg",
                  shadow: "regular",
                }}
              >
                <Box p={6}>
                  <Heading
                    css={{
                      color: "base.gray",
                    }}
                    level={1}
                  >
                    02
                  </Heading>
                  <Heading level={3}>
                    <Trans>Get your answer in a couple of seconds</Trans>
                  </Heading>
                </Box>
                <Box
                  css={{
                    backgroundImage: "url(/onboarding_step_2.svg)",
                    backgroundSize: "cover",
                    backgroundPosition: "left",
                    roundedBottom: "lg",
                    height: "300px",
                  }}
                />
              </Grid>

              <Grid
                columns={1}
                css={{
                  gridTemplateRows: "0.2fr 1fr",
                  border: "1px solid token(colors.base.lightherGray)",
                  bg: "base.white",
                  gap: "0",
                  rounded: "lg",
                  shadow: "regular",
                }}
              >
                <Box p={6} textAlign="left">
                  <Heading
                    css={{
                      color: "base.gray",
                    }}
                    level={1}
                  >
                    03
                  </Heading>
                  <Heading level={3}>
                    <Trans>Save it to a pinboard to have easy access in the future </Trans>
                  </Heading>{" "}
                </Box>
                <Box
                  css={{
                    backgroundImage: "url(/onboarding_step_3.svg)",
                    backgroundSize: "cover",
                    backgroundPosition: "left",
                    roundedBottom: "lg",
                    height: "300px",
                  }}
                />
              </Grid>
            </Grid>
          </VStack>

          <Button
            className={css({
              mt: "xl3",
              width: "20%",
            })}
            onClick={() => navigate(PATHS.ROOT)}
            variant="primary"
          >
            {" "}
            <Trans>Get started</Trans>
          </Button>
        </Box>
      </BackgroundBox>
    </>
  );
};

export { Onboarding };
