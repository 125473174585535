import { ParentDocTypeEnum } from "@api/retriever.i";
import { DataNavigator } from "@components/DataNavigator";
import { ShareResultDialog } from "@components/ShareResultDialog";
import { usePinboard } from "@context/pinboard";
import { Pin } from "@doowii-types/pinboard";
import { DataVizType, DataVizTypes } from "@doowii-types/viz";
import { Box, Flex } from "@styled-system/jsx";
import { Separator } from "doowii-ui";
import { useEffect, useState } from "react";

import { PinDropdown } from "./PinDropdown";
import { ApolloDialog } from "@components/Apollo/ApolloDialog";
import { DynamicChartRenderer } from "@components/DynamicChartRenderer";

interface Props {
  pin: Pin;
}
const PinboardResultCard: React.FC<Props> = ({ pin }) => {
  const { currBoardId } = usePinboard();
  const [isShareResultDialogOpen, setIsShareResultDialogOpen] = useState(false);
  const [isApolloDialogOpen, setIsApolloDialogOpen] = useState(false);

  return (
    <Flex
      css={{
        flexDirection: "column",
        bg: "white",
        borderRadius: "xl",
        boxShadow: "regular",
        overflow: "hidden",
      }}
    >
      <Flex css={{ p: "md", justifyContent: "space-between", alignItems: "center" }}>
        <Flex>{pin.title}</Flex>
        <Box>
          <PinDropdown pin={pin} setIsShareResultDialogOpen={setIsShareResultDialogOpen} />
        </Box>
      </Flex>
      <Separator colorful={false} />
      <Flex css={{ p: "12px 16px", overflow: "auto" }}>
        <DataNavigator
          currentResult={pin}
          parentDocId={currBoardId}
          parentDocType={ParentDocTypeEnum.PINBOARD}
          setIsApolloDialogOpen={setIsApolloDialogOpen}
        />
      </Flex>
      <Box>
        <DynamicChartRenderer
          currentResult={pin}
          parentDocId={currBoardId}
          parentDocType={ParentDocTypeEnum.PINBOARD}
        />
      </Box>
      <ShareResultDialog
        isOpen={isShareResultDialogOpen}
        parentDocId={currBoardId}
        parentDocType={ParentDocTypeEnum.PINBOARD}
        result={pin}
        setIsShareResultDialogOpen={setIsShareResultDialogOpen}
      />
      <ApolloDialog
        isOpen={isApolloDialogOpen}
        parentDocId={currBoardId}
        parentDocType={ParentDocTypeEnum.PINBOARD}
        result={pin}
        setIsOpen={setIsApolloDialogOpen}
      />
    </Flex>
  );
};

export { PinboardResultCard };
