import { usePinboard } from "@context/pinboard";
import { PinboardsView } from "@routes/Pinboards";
import { Flex, Grid } from "@styled-system/jsx";

import { PinboardCard } from "../PinboardCard";
import { Doowii } from "../../../search/Doowii";

interface Props {
  view: PinboardsView;
  searchQuery: string;
  doowii: Doowii;
}
const PinboardCardContainer: React.FC<Props> = ({ view, searchQuery, doowii }) => {
  const { boards } = usePinboard();

  const isGrid = view === "grid";

  return (
    <Flex flex={1} justifyContent="center">
      <Grid
        gap="xl"
        gridAutoRows="max-content"
        gridTemplateColumns={isGrid ? [1, 1, 1, 2, 2, 3] : 1}
        w="full"
      >
        {Array.from(boards)
          .filter(([_, board]) => board.name.toLowerCase().includes(searchQuery.toLowerCase()))
          .map(([key, board]) => (
            <PinboardCard board={board} key={key} view={view} doowii={doowii} />
          ))}
      </Grid>
    </Flex>
  );
};

export default PinboardCardContainer;
