/**
 * Compares two sql through normalizing SQL queries string by removing extra whitespace, standardizing the case, 
 * and trimming leading and trailing whitespace.
 *
 * @param sqlA - The first SQL string to normalize and compare.
 * @param sqlB - The second SQL string to normalize and compare.
 * @returns True/False if the normalized SQL queries are equal.
 */
export function normalizeAndCompareSQL(sqlA: string, sqlB: string): boolean {  
    return normalize(sqlA) === normalize(sqlB);
  }

  const normalize = (sql: string) =>
    sql
      .replace(/\s+/g, '')  // Remove all whitespace characters
      .trim()                // Trim any leading or trailing whitespace (not necessary but kept for safety)
      .toLowerCase();