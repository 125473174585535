import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "@lingui/macro";
import * as Collapsible from "@radix-ui/react-collapsible";
import { css } from "@styled-system/css";
import { Flex } from "@styled-system/jsx";
import { Card, Heading, Separator, Text } from "doowii-ui";
import { useState } from "react";

import { questionsData } from "./questionsData";
const SampleCard = () => {
  const [open, setOpen] = useState(false);

  return (
    <Card>
      <Collapsible.Root className={css({ w: "100%" })} onOpenChange={setOpen} open={open}>
        <Collapsible.Trigger className={css({ w: "100%", cursor: "pointer" })}>
          <Flex align="center" justify="space-between">
            <Heading level={3}>
              <Trans>Sample questions based on IPEDS variables</Trans>
            </Heading>
            <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} size="lg" />
          </Flex>
        </Collapsible.Trigger>

        <Collapsible.Content>
          <Separator className={css({ my: "4" })} colorful={false} />
          <Flex direction="column" gap="4">
            {questionsData.map((question, index) => (
              <Text key={index}>{question}</Text>
            ))}
          </Flex>
        </Collapsible.Content>
      </Collapsible.Root>
    </Card>
  );
};

export { SampleCard };
