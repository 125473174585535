import { ThumbnailBackground } from "@components/ThumbnailBackground";
import { faImage } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "@lingui/macro";
import { css } from "@styled-system/css";
import { Box, Flex } from "@styled-system/jsx";
import { Button, Skeleton, Text } from "doowii-ui";
import React, { useRef, useState } from "react";

interface Props {
  imgUrl?: string;
  isGrid: boolean;
}
const PinboardCardImage: React.FC<Props> = ({ imgUrl, isGrid }) => {
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>();

  const handleAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    // TODO: handle image upload
    console.log(e.target.files);
  };

  return imgUrl ? (
    // <Box css={{ position: "relative", h: isGrid ? 232 : 132, w: isGrid ? "auto" : 250 }}>
    //   <img
    //     alt="pinboard"
    //     className={css({ h: "full", w: "full", objectFit: "cover", borderRadius: "xl" })}
    //     onLoad={() => setIsImgLoaded(true)}
    //     src={imgUrl}
    //   />
    //   {!isImgLoaded ? (
    //     <Skeleton css={{ position: "absolute", top: 0, left: 0, w: "full", height: "full" }} />
    //   ) : null}
    // </Box>
    <ThumbnailBackground
      containerProps={{
        css: { borderRadius: "xl" },
      }}
      css={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: "xl",
        h: isGrid ? 232 : 132,
        w: isGrid ? "auto" : 250,
      }}
    />
  ) : (
    <ThumbnailBackground
      containerProps={{
        css: { borderRadius: "xl" },
      }}
      css={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Flex
        css={{
          flexDirection: "column",
          justifyContent: "center",
          p: "xl",
          h: isGrid ? 232 : 132,
        }}
      >
        <FontAwesomeIcon className={css({ color: "base.blueBrandPrimary" })} icon={faImage} />
        <Text level={2}>
          <span className={css({ display: "flex", alignItems: "center" })}>
            <Button onClick={() => fileInputRef.current?.click()} variant="text">
              <Trans>Add</Trans>
            </Button>
            <Trans>an image to your pinboard</Trans>
          </span>
        </Text>
      </Flex>
      <input
        hidden
        multiple={false}
        onChange={handleAddImage}
        ref={fileInputRef as React.MutableRefObject<HTMLInputElement>}
        type="file"
      />
    </ThumbnailBackground>
  );
};

export default PinboardCardImage;
