import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { Trans } from "@lingui/macro";
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  CircularIcon,
} from "doowii-ui";

interface DeleteChatDialogProps {
  onConfirm: () => void;
}

const DeleteChatDialog = ({ onConfirm }: DeleteChatDialogProps) => (
  <AlertDialogContent>
    <AlertDialogHeader>
      <CircularIcon css={{ marginBottom: "md" }} icon={faTrash} />
      <AlertDialogTitle>
        <Trans>Delete Chat?</Trans>
      </AlertDialogTitle>
      <AlertDialogDescription>
        <Trans>Are you sure you want to delete this chat?</Trans>
      </AlertDialogDescription>
    </AlertDialogHeader>
    <AlertDialogFooter>
      <AlertDialogCancel>
        <Trans>Cancel</Trans>
      </AlertDialogCancel>
      <AlertDialogAction destructive onClick={onConfirm} type="submit">
        <Trans>Delete</Trans>
      </AlertDialogAction>
    </AlertDialogFooter>
  </AlertDialogContent>
);

export { DeleteChatDialog };
