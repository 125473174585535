import { PATHS } from "@constants/Paths";
import { useChatData } from "@context/chat";
import { useAuth } from "@hooks/useAuth";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Box } from "@styled-system/jsx";
import { stopPropagationWrapper } from "@utils/stopPropagationWrapper";
import { Skeleton, SuggestionCard, Tooltip } from "doowii-ui";
import React from "react";
import { useNavigate } from "react-router-dom";

import { Doowii } from "../../../search/Doowii";
import { QuestionTypeEnum } from "../../../search/Doowii.i";

const ElipsisTextStyles = {
  "& button": {
    p: "12px 16px",
    minHeight: "72px",
    h: "72px!",
    maxWidth: "100%",
  },
  "& button > div": {
    display: "-webkit-box",
    overflow: "hidden",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
};

interface props {
  doowii: Doowii;
}

const PinboardCardSuggestions: React.FC<props> = ({ doowii }) => {
  const { _ } = useLingui();
  const { dynamicCache, fetchingSuggestions, currentThread } = useChatData();
  const { authUser } = useAuth();
  const navigate = useNavigate();

  const onHandleSuggestionClick = async (suggestion: string) => {
    navigate(`/${PATHS.USERS}/${authUser?.uid}/${PATHS.THREADS}/${currentThread}`);
    await doowii.chat({
      query: suggestion,
      index: 0,
      questionType: QuestionTypeEnum.SUGGESTION,
    });
  };

  return fetchingSuggestions ? (
    <>
      <Skeleton height="72px" maxWidth="100%" w="full" />
      <Skeleton height="72px" maxWidth="100%" w="full" />
      <Skeleton height="72px" maxWidth="100%" w="full" />
    </>
  ) : (
    dynamicCache.slice(1, 4).map((suggestion, index) => (
      <Tooltip content={suggestion} key={index}>
        <Box css={ElipsisTextStyles}>
          <SuggestionCard
            label={_(msg`Start new chat with suggestion: ${suggestion}`)}
            onClick={stopPropagationWrapper(() => onHandleSuggestionClick(suggestion))}
          >
            {suggestion}
          </SuggestionCard>
        </Box>
      </Tooltip>
    ))
  );
};

export { PinboardCardSuggestions };
