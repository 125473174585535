import { useAuth } from "@hooks/useAuth";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Avatar, AvatarFallback, AvatarImage } from "doowii-ui";

const getInitials = (name: string) => {
  const parts = name.split(/[ @]/u);
  return parts.length > 1
    ? `${parts[0][0]}${parts[1][0]}`.toUpperCase()
    : parts[0].slice(0, 2).toUpperCase();
};

const UserAvatar = () => {
  const { authUser } = useAuth();
  const { _ } = useLingui();

  const userDisplayName = authUser?.displayName ?? authUser?.email ?? _(msg`Unknown User`);
  return (
    <Avatar>
      <AvatarImage src={authUser?.photoURL as string} />
      <AvatarFallback>{getInitials(userDisplayName)}</AvatarFallback>
    </Avatar>
  );
};

export { UserAvatar };
