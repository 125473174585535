import React from "react";
import { Result } from "@doowii-types/chat";
import { Pin } from "@doowii-types/pinboard";
import { Accordion, Text } from "doowii-ui";
import { css } from "@doowii/design-system/css";

interface ApolloGuideProps {
  result: Result | Pin;
  setIsAccordionOpen: (open: boolean) => void;
  isAccordionOpen: boolean;
}

const ulStyle = css({
  paddingLeft: "16px",
});

const liStyle = css({
  marginBottom: "8px",
});

const boldTextStyle = css({
  fontWeight: "bold",
});

const italicTextStyle = css({
  fontStyle: "italic",
});

const availableColumnsUlStyle = css({
  padding: 0,
  listStyleType: "none",
});

const availableColumnsLiStyle = css({
  marginBottom: "8px",
  display: "flex",
  alignItems: "center",
});

const boldTextWithMarginRightStyle = css({
  fontWeight: "bold",
  marginRight: "8px",
});

const grayItalicTextStyle = css({
  color: "{colors.base.gray}",
  fontStyle: "italic",
});

const ModifyChartColors = () => (
  <ul className={ulStyle}>
    <li className={liStyle}>
      <Text>
        <Text className={boldTextStyle}>Modify specific elements:</Text> "Change the bar color to
        blue"
      </Text>
    </li>
    <li className={liStyle}>
      <Text>
        <Text className={boldTextStyle}>Update overall theme:</Text> "Use a pastel color scheme"
      </Text>
    </li>
    <li className={liStyle}>
      <Text>
        <Text className={boldTextStyle}>Highlight data:</Text> "Make the datalabels red"
      </Text>
    </li>
  </ul>
);

const ModifyAxisLabels = () => (
  <ul className={ulStyle}>
    <li className={liStyle}>
      <Text>
        <Text className={boldTextStyle}>Rename axes:</Text> "Change x-axis label to 'Months'"
      </Text>
    </li>
    <li className={liStyle}>
      <Text>
        <Text className={boldTextStyle}>Format values:</Text> "Round data labels to 2 decimal
        points"
      </Text>
    </li>
  </ul>
);

const ToggleDataLabelsAndTooltips = () => (
  <ul className={ulStyle}>
    <li className={liStyle}>
      <Text>
        <Text className={boldTextStyle}>Show/hide data labels:</Text> "Display values on each bar"
      </Text>
    </li>
    <li className={liStyle}>
      <Text>
        <Text className={boldTextStyle}>Customize tooltips:</Text> "Add product names to tooltips"
      </Text>
    </li>
  </ul>
);

const ModifyChartLegend = () => (
  <ul className={ulStyle}>
    <li className={liStyle}>
      <Text>
        <Text className={boldTextStyle}>Reposition:</Text> "Move legend to the bottom"
      </Text>
    </li>
    <li className={liStyle}>
      <Text>
        <Text className={boldTextStyle}>Hide:</Text> "Remove legend from the chart"
      </Text>
    </li>
  </ul>
);

const determineColumns = (
  columnTypes: Record<string, "categorical" | "numerical" | "temporal">
): { categoricalColumn: string; numericalColumn: string } => {
  const categoricalColumn =
    Object.keys(columnTypes).find((key) => columnTypes[key] === "categorical") ||
    "Categorical Placeholder";
  const numericalColumn =
    Object.keys(columnTypes).find((key) => columnTypes[key] === "numerical") ||
    "Numerical Placeholder";

  return { categoricalColumn, numericalColumn };
};

const ChangeChartType = ({
  columnTypes,
}: {
  columnTypes: Record<string, "categorical" | "numerical" | "temporal">;
}) => {
  const { categoricalColumn, numericalColumn } = determineColumns(columnTypes);

  return (
    <>
      <ul className={ulStyle}>
        <li className={liStyle}>
          <Text>
            <Text className={boldTextStyle}>Switch chart types:</Text> "Change from bar to line
            chart using {categoricalColumn} and {numericalColumn}"
          </Text>
        </li>
      </ul>
      <br />
      <Text className={italicTextStyle}>
        Remember: Ensure your data is compatible with the new format and specify the columns you
        want to use for the chart.
      </Text>
    </>
  );
};

const AvailableColumns = ({
  columnTypes,
}: {
  columnTypes: Record<string, "categorical" | "numerical" | "temporal">;
}) => {
  return (
    <div>
      <ul className={availableColumnsUlStyle}>
        {Object.keys(columnTypes).map((column) => (
          <li key={column} className={availableColumnsLiStyle}>
            <Text className={boldTextWithMarginRightStyle}>{column}</Text>
            <Text className={grayItalicTextStyle}>({columnTypes[column]})</Text>
          </li>
        ))}
      </ul>
    </div>
  );
};

const ApolloGuide: React.FC<ApolloGuideProps> = ({
  result,
  isAccordionOpen,
  setIsAccordionOpen,
}) => {
  const columnTypes = result?.chartConfig?.column_types || {};

  return (
    <Accordion
      onValueChange={() => setIsAccordionOpen(!isAccordionOpen)}
      title="Chart Modification Guide"
      rows={[
        {
          title: "Available Columns",
          content: <AvailableColumns columnTypes={columnTypes} />,
        },
        { title: "Modify Chart Colors", content: <ModifyChartColors /> },
        { title: "Modify Axis Labels", content: <ModifyAxisLabels /> },
        {
          title: "Toggle Data Labels and Tooltips",
          content: <ToggleDataLabelsAndTooltips />,
        },
        { title: "Modify Chart Legend", content: <ModifyChartLegend /> },
        {
          title: "Change Chart Type",
          content: <ChangeChartType columnTypes={columnTypes} />,
        },
      ]}
    />
  );
};

export default ApolloGuide;
