// types/chartConfig.ts

import { ChartConfig } from '@doowii-types/chart';
import { ParentDocTypeEnum } from '@api/retriever.i';
import create, { StateCreator } from 'zustand';

export interface Message {
  author: string;
  message: string;
}

export interface ChartConfigState {
  tempChartConfig: ChartConfig;
  chartConfig: ChartConfig;
  conversation: Message[];
  loadingModification: boolean;
  optionsToModify: Record<string, any>;
}

export interface ChartConfigSliceInterface {
  instances: { [key: string]: ChartConfigState };
  initializeInstance: (key: string, initialState: ChartConfigState) => void;
  updateInstance: (key: string, newState: Partial<ChartConfigState>) => void;
  getInstance: (key: string) => ChartConfigState | undefined;
}


const createChartConfigSlice: StateCreator<ChartConfigSliceInterface> = (set, get) => ({
    instances: {},
    initializeInstance: (key: string, initialState: ChartConfigState) => {
      const instances = get().instances;
      if (!instances[key]) {
        set({
          instances: {
            ...instances,
            [key]: initialState,
          },
        });
      }
    },
    updateInstance: (key: string, newState: Partial<ChartConfigState>) => {
      const instances = get().instances;
      const instance = instances[key];
      if (instance) {
        set({
          instances: {
            ...instances,
            [key]: {
              ...instance,
              ...newState,
            },
          },
        });
      }
    },
    getInstance: (key: string) => {
      return get().instances[key];
    },
  });
  
  export const useChartConfigStore = create<ChartConfigSliceInterface>(createChartConfigSlice);