import { useEffect } from 'react';
import { ChartConfig } from '@doowii-types/chart';
import { withSentry } from '@utils/wrapper';
import { useAuth } from './useAuth';
import { useChatData } from '../context/chat';
import { usePinboard } from '../context/pinboard';
import { db } from '../services/firebase';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { useToast } from 'doowii-ui';
import { ParentDocTypeEnum } from '@api/retriever.i';
import { handleChartModification } from '@components/DynamicChartRenderer/utils';
import { generateKey } from '../utils/keyGenerator';
import { useChartConfigStore } from '@stores/ chartConfigStore';

interface UseChartConfigProps {
  initialConfig: ChartConfig;
  parentType: ParentDocTypeEnum;
  parentId: string;
  chatId?: string;
}

export const useChartConfig = ({
  initialConfig,
  parentType,
  parentId,
  chatId,
}: UseChartConfigProps) => {
  const key = generateKey(parentType, parentId, chatId);
  const { userDocument } = useAuth();
  const { updateResultsFields } = useChatData();
  const { pinboardResults, setPinboardResults } = usePinboard();
  const { toast } = useToast();

  // Zustand store functions
  const initializeInstance = useChartConfigStore((state) => state.initializeInstance);
  const updateInstance = useChartConfigStore((state) => state.updateInstance);

  // Initialize the state instance if it doesn't exist
  useEffect(() => {
    initializeInstance(key, {
      tempChartConfig: initialConfig,
      chartConfig: initialConfig,
      conversation: [{ author: 'Doowii', message: 'Open the guide above to see what you can do with this chart.' }],
      loadingModification: false,
      optionsToModify: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  // Retrieve the state instance for this key
  const chartState = useChartConfigStore((state) => state.instances[key]);;

  if (!chartState) {
    // State is not yet initialized
    return {
      tempChartConfig: initialConfig,
      chartConfig: initialConfig,
      conversation: [],
      loadingModification: false,
      updateConfigState: async () => {},
      updateConfigStateLocally: () => {},
      handleChartConfigUpdate: async () => {},
      resetTemporaryState: () => {},
      updateOptionsToModify: () => {}
    };
  }

  /**
   * Updates the chart configuration in Firestore depending on the parent type.
   */
  const updateChartConfigInFirestore = withSentry(async (newConfig: ChartConfig) => {
    if (!userDocument || !userDocument.organization) {
      console.error('User document not found');
      return;
    }

    try {
      if (parentType === ParentDocTypeEnum.THREAD) {
        const chatDocRef = doc(
          db,
          'organizations',
          userDocument.organization,
          'threads',
          parentId,
          'chats',
          chatId!
        );
        const chatDoc = await getDoc(chatDocRef);
        if (!chatDoc.exists()) {
          throw new Error('Chat document does not exist');
        }

        await updateDoc(chatDocRef, {
          chart_config: newConfig,
        });

        // Update local state
        updateResultsFields({ chartConfig: newConfig }, chatId);
      } else if (parentType === ParentDocTypeEnum.PINBOARD) {
        const pinDocRef = doc(
          db,
          'organizations',
          userDocument.organization,
          'pinboards',
          parentId,
          'pins',
          chatId!
        );
        const pinDoc = await getDoc(pinDocRef);
        if (!pinDoc.exists()) {
          throw new Error('Pin document does not exist');
        }

        await updateDoc(pinDocRef, {
          chartConfig: newConfig,
        });

        // Update local state
        const updatedPins = pinboardResults.map((pin) =>
          pin.id === chatId ? { ...pin, chartConfig: newConfig } : pin
        );
        setPinboardResults(updatedPins);
      } else {
        throw new Error('Invalid parent type');
      }

    } catch (error) {
      console.error('Error updating chart configuration in Firestore:', error);
      toast({
        status: 'error',
        title: 'Failed to update chart configuration. Please try again later.',
      });
    }
  });

  /**
   * Updates the chart configuration state locally without updating Firestore.
   */
  const updateConfigStateLocally = (newConfig: ChartConfig) => {
    updateInstance(key, { chartConfig: newConfig });

    // Update local state in context
    if (parentType === ParentDocTypeEnum.THREAD) {
      updateResultsFields({ chartConfig: newConfig }, chatId);
    } else if (parentType === ParentDocTypeEnum.PINBOARD) {
      const updatedPins = pinboardResults.map((pin) =>
        pin.id === chatId ? { ...pin, chartConfig: newConfig } : pin
      );
      setPinboardResults(updatedPins);
    }
  };

  /**
   * Handles updating the chart configuration. Updates Firestore by default unless specified.
   */
  const updateConfigState = async (
    newConfig: ChartConfig,
    updateFirestore = true
  ) => {
    updateInstance(key, { chartConfig: newConfig });
    if (updateFirestore) {
      await updateChartConfigInFirestore(newConfig);
    } else {
      updateConfigStateLocally(newConfig);
    }
  };

  const handleChartConfigUpdate = async (query: string) => {
    updateInstance(key, { loadingModification: true });
    try {
      const currentConversation = chartState.conversation;
      const updatedConversation = [...currentConversation, { author: 'User', message: query }];
      updateInstance(key, { conversation: updatedConversation });
      const response = await handleChartModification(query, {...chartState.tempChartConfig, options: {config: chartState.optionsToModify}});

      const newConversation = [
        ...updatedConversation,
        { author: 'Doowii', message: response.explanation },
      ];
      if (response.suggestions && response.suggestions.length > 0) {
        for (const suggestion of response.suggestions) {
          newConversation.push({ author: 'Doowii', message: suggestion });
        }
      }
      updateInstance(key, { conversation: newConversation });


      updateInstance(key, {tempChartConfig: response.chart_config});
    } catch (error) {
      const errorConversation = [
        ...chartState.conversation,
        {
          author: 'Doowii',
          message:
            "Sorry, I'm having some trouble processing your request. Please try again later...",
        },
      ];
      updateInstance(key, { conversation: errorConversation });
    } finally {
      updateInstance(key, { loadingModification: false });
    }
  };

  const resetTemporaryState = () => {
    updateInstance(key, { tempChartConfig: chartState.chartConfig });
    if(chartState.chartConfig.options && chartState.chartConfig.suggestion.toLowerCase() !== chartState.chartConfig.options.config.chart.type.toLowerCase()) {
      updateInstance(key, {tempChartConfig: {...chartState.chartConfig, options: null}});
    }
    updateInstance(key, { conversation: [{ author: 'Doowii', message: `Open the guide above to see what you can do with this chart.` }] });
  }

  const updateOptionsToModify = (options: Record<string, any>) => {
    updateInstance(key, { optionsToModify: options });
  }

  return {
    tempChartConfig: chartState.tempChartConfig,
    chartConfig: chartState.chartConfig,
    conversation: chartState.conversation,
    loadingModification: chartState.loadingModification,
    updateConfigState,
    updateConfigStateLocally,
    handleChartConfigUpdate,
    resetTemporaryState,
    updateOptionsToModify
  };
};
