import { Board } from "@doowii-types/pinboard";
import { faEllipsisVertical, faPen, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { stopPropagationWrapper } from "@utils/stopPropagationWrapper";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Tooltip,
} from "doowii-ui";
import { useId, useRef, useState } from "react";

import { DeletePinboardDialog } from "./DeletePinboardDialog";
import { EditPinboardNameDialog } from "./EditPinboardNameDialog";

interface Props {
  board: Board;
}
const PinboardCardDropdown: React.FC<Props> = ({ board }) => {
  const id = useId();
  const { _ } = useLingui();
  const [openEditNameDialog, setOpenEditNameDialog] = useState(false);
  const [openDeleteNameDialog, setOpenDeleteNameDialog] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>();

  const handleAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    // TODO: handle image upload
    console.log(e.target.files);
  };

  const triggerId = `${id}-trigger`;

  return (
    <>
      <DropdownMenu modal={false}>
        <Tooltip content={_(msg`See pinboard options`)}>
          <DropdownMenuTrigger asChild onClick={(e) => e.stopPropagation()}>
            <Button
              aria-label={_(msg`See pinboard options`)}
              iconOnly={faEllipsisVertical}
              id={triggerId}
              variant="dropdown"
            />
          </DropdownMenuTrigger>
        </Tooltip>
        <DropdownMenuContent align="end">
          <DropdownMenuGroup>
            <DropdownMenuItem onClick={stopPropagationWrapper(() => setOpenEditNameDialog(true))}>
              <FontAwesomeIcon icon={faPen} />
              <Trans>Edit name</Trans>
            </DropdownMenuItem>
            {/* TODO: Replace with img check */}
            {/* {board.name ? (
              <DropdownMenuItem onClick={() => fileInputRef.current?.click()}>
                <FontAwesomeIcon icon={faImage} />
                <Trans>Change thumbnail</Trans>
                <input
                  hidden
                  multiple={false}
                  onChange={handleAddImage}
                  ref={fileInputRef as React.MutableRefObject<HTMLInputElement>}
                  type="file"
                />
              </DropdownMenuItem>
            ) : null} */}
            <DropdownMenuItem onClick={stopPropagationWrapper(() => setOpenDeleteNameDialog(true))}>
              <FontAwesomeIcon icon={faTrash} />
              <Trans>Delete</Trans>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>

      <EditPinboardNameDialog
        boardId={board.id}
        boardName={board.name}
        isOpen={openEditNameDialog}
        setIsOpen={setOpenEditNameDialog}
        triggerId={triggerId}
      />

      <DeletePinboardDialog
        boardId={board.id}
        isOpen={openDeleteNameDialog}
        setIsOpen={setOpenDeleteNameDialog}
        triggerId={triggerId}
      />
    </>
  );
};

export default PinboardCardDropdown;
