import { ParentDocTypeEnum } from "@api/retriever.i";
import { CreatePinboardDialog } from "@components/PinboardCard";
import { PATHS } from "@constants/Paths";
import { useChatData } from "@context/chat";
import { usePinboard } from "@context/pinboard";
import {
  faDownload,
  faEllipsisVertical,
  faSquareDashedCirclePlus,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "@hooks/useAuth";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { css } from "@styled-system/css";
import { downloadData } from "@utils/index";
import {
  AlertDialog,
  AlertDialogTrigger,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
  Tooltip,
  useToast,
} from "doowii-ui";
import { useId, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DeleteChatDialog } from "./DeleteChatDialog";

interface ChatMenuProps {
  chatId: string;
  title: string;
}

const ChatMenu = ({ chatId, title }: ChatMenuProps) => {
  const id = useId();
  const { currentThread, deleteChatFromThread } = useChatData();
  const { boards, pinToBoard } = usePinboard();
  const { toast } = useToast();
  const { _ } = useLingui();

  const { authUser } = useAuth();
  const navigate = useNavigate();
  const handleDownloadData = async () => {
    await downloadData({
      docId: chatId,
      parentDocId: currentThread,
      parentDocType: ParentDocTypeEnum.THREAD,
      filename: `${title}.csv`,
    });
  };

  const navigateToThreads = () => {
    navigate(`/${PATHS.USERS}/${authUser?.uid}/${PATHS.THREADS}`);
  };

  const handleChatDelete = async () => {
    await deleteChatFromThread(chatId, navigateToThreads);
  };

  const handlePinToBoard = async (boardId: string) => {
    await pinToBoard(chatId, boardId);
  };
  const [isCreatePinboardDialogOpen, setIsCreatePinboardDialogOpen] = useState(false);

  const triggerId = `${id}-trigger`;

  return (
    <>
      <DropdownMenu modal={false}>
        <Tooltip content={_(msg`More options`)}>
          <DropdownMenuTrigger asChild>
            <Button iconOnly={faEllipsisVertical} id={triggerId} size="small" variant="dropdown" />
          </DropdownMenuTrigger>
        </Tooltip>

        <DropdownMenuContent align="end" className={css({ w: "56" })}>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <Trans>Add to Pinboard</Trans>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <DropdownMenuItem onClick={() => setIsCreatePinboardDialogOpen(true)}>
                  <Trans>New Pinboard</Trans>
                </DropdownMenuItem>

                <DropdownMenuSeparator />
                {Array.from(boards.keys()).map((key, index) => (
                  <DropdownMenuItem key={index} onClick={() => handlePinToBoard(key)}>
                    <FontAwesomeIcon icon={faSquareDashedCirclePlus} />
                    {boards.get(key).name}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
          <DropdownMenuSeparator />

          <DropdownMenuItem onClick={() => handleDownloadData()}>
            <FontAwesomeIcon icon={faDownload} />
            <Trans>Download Data</Trans>
          </DropdownMenuItem>

          <AlertDialog>
            <AlertDialogTrigger asChild>
              <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                <FontAwesomeIcon icon={faTrash} />
                <Trans>Delete Chat</Trans>
              </DropdownMenuItem>
            </AlertDialogTrigger>
            <DeleteChatDialog onConfirm={handleChatDelete} />
          </AlertDialog>
        </DropdownMenuContent>
      </DropdownMenu>
      <CreatePinboardDialog
        chatIdToPin={chatId}
        isOpen={isCreatePinboardDialogOpen}
        setIsOpen={setIsCreatePinboardDialogOpen}
        triggerId={triggerId}
      />
    </>
  );
};

export { ChatMenu };
