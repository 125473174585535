import { msg } from "@lingui/macro";

export const HIGHER_ED = "higher_ed";
export const TABLE = "TABLE";
export const LOADING_PREFETCH = msg`Just a few more seconds!`;
export const LOADING_START = msg`We're on the case!`;
export const LOADING_P1 = msg`Digging through the data...Thanks for your patience!`;
export const LOADING_P2 = msg`The data is on its way, hang tight!`;
export const LOADING_P3 = msg`Just adding some finishing touches...`;
export const RETRY = msg`Hmmm, this data is tricky to find. I will try extra hard!`;
export const PIN_PRE_FETCH_SIZE = 1000;

export const CANCELED = "CANCELED";

export const SUCCESS = "SUCCESS";
export const UPLOADED = "UPLOADED";
export const FAILED = "FAILED";

export const GENERAL_TIPS = [
  {
    title: msg`Give Exact Names`,
    description: msg`If you are referring to a specific course or person, make sure to put their name exactly as it would appear in a database.`,
  },
  {
    title: msg`Be Specific`,
    description: msg`When referring to an item, qualify the item with what domain it is related to (courses, classes, attendance, demographics).`,
  },
  {
    title: msg`Quantify`,
    description: msg`When asking for a quality that needs to be interpreted, specify how the chatbot should interpret it.`,
  },
];
