/* eslint-disable @typescript-eslint/no-explicit-any */
import { logEvent, setUserId, setUserProperties } from "firebase/analytics";

import { analytics } from "../firebase/connection";

export class Analytics {
  static track = (event: string, payload?: any) => {
    logEvent(analytics, event, { time: Date.now(), ...payload });
  };

  static trackPageView = (page: string) => {
    logEvent(analytics, "screen_view", {
      firebase_screen: page,
      firebase_screen_class: page,
    });
  };

  static login = (id: string) => {
    setUserId(analytics, id);
  };

  static addUserInformation = (proprieties: any) => {
    setUserProperties(analytics, proprieties);
  };
}
