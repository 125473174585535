import { ParentDocTypeEnum } from "@api/retriever.i";
import { PATHS } from "@constants/Paths";
import { useChatData } from "@context/chat";
import { usePinboard } from "@context/pinboard";
import { Pin } from "@doowii-types/pinboard";
import {
  faDownload,
  faEllipsisVertical,
  faExpand,
  faMessageLines,
  faPen,
  faShare,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "@hooks/useAuth";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { downloadData } from "@utils/downloadData";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Tooltip,
} from "doowii-ui";
import { useEffect, useId, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DeletePinDialog } from "./DeletePinDialog";
import { EditPinTitleDialog } from "./EditPinTitleDialog";
import { FullScreenDialog } from "./FullScreenDialog";

interface Props {
  pin: Pin;
  setIsShareResultDialogOpen: (isOpen: boolean) => void;
}
const PinDropdown: React.FC<Props> = ({ pin, setIsShareResultDialogOpen }) => {
  const id = useId();
  const { _ } = useLingui();
  const navigate = useNavigate();
  const { authUser } = useAuth();
  const { navigateToChatFromPin, isChatAvailable } = useChatData();
  const [isEditPinTitleDialogOpen, setIsEditPinTitleDialogOpen] = useState(false);
  const [isDeletePinDialogOpen, setIsDeletePinDialogOpen] = useState(false);
  const { currBoardId } = usePinboard();
  const [isFullScreenDialogOpen, setIsFullScreenDialogOpen] = useState(false);
  const [showViewChat, setShowViewChat] = useState(false);

  const handleDownloadData = async () => {
    await downloadData({
      docId: pin.id,
      parentDocId: pin.boardId || currBoardId,
      parentDocType: ParentDocTypeEnum.PINBOARD,
      filename: `${pin.title}.csv`,
    });
  };

  const handleNavigateToThread = async () => {
    const threadId = await navigateToChatFromPin(pin.id);
    navigate(`/${PATHS.USERS}/${authUser.uid}/${PATHS.THREADS}/${threadId}/#${pin.id}`);
  };

  useEffect(() => {
    (async () => {
      const chatAvailable = await isChatAvailable(pin.id);
      setShowViewChat(chatAvailable);
    })();
  }, [pin, isChatAvailable]);

  const triggerId = `${id}-trigger`;

  return (
    <>
      <DropdownMenu modal={false}>
        <Tooltip content={_(msg`Open pin options`)}>
          <DropdownMenuTrigger asChild>
            <Button
              aria-label={_(msg`Open pin options`)}
              iconOnly={faEllipsisVertical}
              id={triggerId}
              variant="dropdown"
            />
          </DropdownMenuTrigger>
        </Tooltip>
        <DropdownMenuContent align="end">
          <DropdownMenuGroup>
            <DropdownMenuItem
              onClick={() => {
                setIsEditPinTitleDialogOpen(true);
              }}
            >
              <FontAwesomeIcon icon={faPen} />
              <Trans>Edit pin title</Trans>
            </DropdownMenuItem>

            {/* TODO: Enable when move is enabled. 
            <DropdownMenuItem>
              <FontAwesomeIcon icon={faTurnDownRight} />
              <Trans>Move to other pinboard</Trans>
            </DropdownMenuItem> */}

            <DropdownMenuItem onClick={() => setIsFullScreenDialogOpen(true)}>
              <FontAwesomeIcon icon={faExpand} />
              <Trans>Full screen</Trans>
            </DropdownMenuItem>

            <DropdownMenuItem disabled={!showViewChat} onClick={() => handleNavigateToThread()}>
              <FontAwesomeIcon icon={faMessageLines} />
              <Trans>View chat</Trans>
            </DropdownMenuItem>

            <DropdownMenuItem onClick={() => setIsShareResultDialogOpen(true)}>
              <FontAwesomeIcon icon={faShare} />
              <Trans>Share result</Trans>
            </DropdownMenuItem>

            <DropdownMenuItem onClick={() => handleDownloadData()}>
              <FontAwesomeIcon icon={faDownload} />
              <Trans>Download data</Trans>
            </DropdownMenuItem>

            <DropdownMenuItem onClick={() => setIsDeletePinDialogOpen(true)}>
              <FontAwesomeIcon icon={faTrash} />
              <Trans>Delete</Trans>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>

      <EditPinTitleDialog
        isOpen={isEditPinTitleDialogOpen}
        pinId={pin.id}
        pinTitle={pin.title}
        pinboardId={pin.boardId || currBoardId}
        setIsOpen={setIsEditPinTitleDialogOpen}
        triggerId={triggerId}
      />

      <DeletePinDialog
        boardId={pin.boardId || currBoardId}
        isOpen={isDeletePinDialogOpen}
        pinId={pin.id}
        setIsOpen={setIsDeletePinDialogOpen}
        triggerId={triggerId}
      />

      <FullScreenDialog
        isOpen={isFullScreenDialogOpen}
        pin={pin}
        setIsOpen={setIsFullScreenDialogOpen}
        triggerId={triggerId}
      />
    </>
  );
};
export { PinDropdown };
