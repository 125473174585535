import { PATHS } from "@constants/Paths";
import { useBoundStore } from "@stores/store";
import { redirect } from "react-router-dom";
import { env } from "../../env";
import { HIGHER_ED } from "@constants/constants";

const rootLoader = async () => {
  const isAuthenticated = Boolean(useBoundStore.getState().authUser);
  if (!isAuthenticated) {
    return redirect(env.REACT_APP_ENV === HIGHER_ED ? PATHS.SIGN_UP : PATHS.SIGN_IN);
  }
  return null;
};

export { rootLoader };
