/* eslint-disable react/jsx-max-depth */
import "@fontsource-variable/outfit";
import "./index.css";

import AuthInitializer from "@context/auth/AuthInitializer";
import { ChatDataProvider } from "@context/chat";
import { DoowiiProvider } from "@context/doowii/doowii";
import { LocaleProvider } from "@context/locale";
import { PinboardProvider } from "@context/pinboard";
import { OrganizationUsersProvider } from "@context/users";
import RouterWrapper from "@routes/RouterWrapper";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "doowii-ui";
import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";

import { env } from "./env";

const queryClient = new QueryClient();

Sentry.init({
  dsn: "https://946b8670c6e1eb35b3f88d47a78ee39a@o4505761205649408.ingest.us.sentry.io/4507936248496128",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["https://ask.doowii.io", "https://staging.doowii.io"],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: env.REACT_APP_ENV === "prod" || env.REACT_APP_ENV === "staging",
  environment: env.REACT_APP_ENV,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <LocaleProvider>
      <AuthInitializer>
        <ChatDataProvider>
          <PinboardProvider>
            <QueryClientProvider client={queryClient}>
              <DoowiiProvider>
                <OrganizationUsersProvider>
                  <HelmetProvider>
                    <RouterWrapper />
                  </HelmetProvider>
                </OrganizationUsersProvider>
              </DoowiiProvider>
              <Toaster />
            </QueryClientProvider>
          </PinboardProvider>
        </ChatDataProvider>
      </AuthInitializer>
    </LocaleProvider>
  </React.StrictMode>
);
