import { LearnMoreSidePanel } from "@components/LearnMoreSidePanel";
import { PageHeader } from "@components/PageHeader";
import { SuggestionsCardsContainer } from "@components/SuggestionsCardsContainer";
import { TipsModal } from "@components/TipsModal/TipsModal";
import { HIGHER_ED } from "@constants/constants";
import { PATHS } from "@constants/Paths";
import { useChatData } from "@context/chat";
import { useAuth } from "@hooks/useAuth";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Flex } from "@styled-system/jsx";
import { Heading, MainSearchBar } from "doowii-ui";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { env } from "../env";
import { Doowii } from "../search/Doowii";

const Home = () => {
  const navigate = useNavigate();

  const { userDocument, authUser } = useAuth();
  const {
    allResults,
    setAllResults,
    setAnswer,
    setLoading,
    setStreamLoading,
    currentThread,
    threads,
    newChat,
  } = useChatData();

  const { _, i18n } = useLingui();
  useEffect(() => {
    newChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doowii = new Doowii(
    i18n,
    setLoading,
    setAllResults,
    setAnswer,
    setStreamLoading,
    userDocument,
    currentThread,
    allResults,
    threads
  );

  const handleSubmit = async (inputValue: string) => {
    navigate(`/${PATHS.USERS}/${authUser?.uid}/${PATHS.THREADS}/${currentThread}`);
    console.log("home searh bar allResults", allResults);
    await doowii.chat({
      query: inputValue,
      index: 0,
    });
  };

  return (
    <>
      <PageHeader description={_(msg`Doowii home page`)} title={_(msg`Doowii - Home page`)} />
      <Flex
        css={{
          flexDirection: "column",
          alignItems: "center",
          gap: "xl6",
          mt: "xl6",
          w: "full",
          p: "xl",
        }}
      >
        <Flex
          css={{
            flexDirection: "column",
            alignItems: "center",
            gap: "ld",
            w: "full",
          }}
        >
          <Heading css={{ textAlign: "center" }} level={1}>
            <Trans>What data do you need today?</Trans>
          </Heading>
          <MainSearchBar
            label={_(msg`Start a new chat with Doowii`)}
            onSubmit={handleSubmit}
            placeholder="Message doowii"
          />
          {env.REACT_APP_ENV === HIGHER_ED ? <LearnMoreSidePanel /> : <TipsModal />}
        </Flex>

        <SuggestionsCardsContainer doowii={doowii} homePage={true} />
      </Flex>
    </>
  );
};

export { Home };
