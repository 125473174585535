import { i18n } from "@lingui/core";
import { detect, fromStorage, fromUrl } from "@lingui/detect-locale";
import { I18nProvider } from "@lingui/react";
import React, { createContext, FC, useContext, useEffect, useLayoutEffect, useState } from "react";

type LocaleMessages = { messages: Record<string, string> };

const localizedMessages: Record<string, () => Promise<unknown>> = import.meta.glob(
  "../../locales/**/messages.po"
);

// can be a function with custom logic or just a string, `detect` method will handle it
const DEFAULT_FALLBACK = () => "en";

const defaultLocale = detect(fromUrl("lang"), fromStorage("lang"), DEFAULT_FALLBACK) as string;

const loadLocale = async (locale: string) => {
  console.log(`Loading locale "${locale}"`);

  const { messages } = (await localizedMessages[`../../locales/${locale}/messages.po`]().catch(
    (e) => {
      console.error(e);
      console.warn(`No locale file found for ${locale}`);
      return { messages: {} };
    }
  )) as LocaleMessages;
  i18n.loadAndActivate({ locale, messages });
};

const LocaleContext = createContext({
  locale: defaultLocale,
  setLocale: (locale: string) => {},
  isRTL: false,
});

const rtlLanguages = ["ar", "he", "fa"];

const LocaleProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [locale, setLocale] = useState(defaultLocale);
  const [dir, setDir] = useState<"ltr" | "rtl">(
    rtlLanguages.includes(defaultLocale) ? "rtl" : "ltr"
  );

  useEffect(() => {
    void loadLocale(locale);
    const newDir = rtlLanguages.includes(locale) ? "rtl" : "ltr";
    setDir(newDir);
  }, [locale]);

  useLayoutEffect(() => {
    document.documentElement.lang = locale;
    document.documentElement.dir = dir;
    document.body.style.direction = dir;
  }, [locale, dir]);

  return (
    <LocaleContext.Provider value={{ locale, setLocale, isRTL: dir === "rtl" }}>
      <I18nProvider i18n={i18n}>
        <div style={{ direction: dir }}>{children}</div>
      </I18nProvider>
    </LocaleContext.Provider>
  );
};
// Useful for accessing & setting locale from anywhere in the app
const useLocaleContext = () => useContext(LocaleContext);

export { LocaleProvider, useLocaleContext };
