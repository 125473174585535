import { MarkdownText } from "@components/MarkdownText";
import { PATHS } from "@constants/Paths";
import { useChatData } from "@context/chat";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { useAuth } from "@hooks/useAuth";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { css } from "@styled-system/css";
import { Flex } from "@styled-system/jsx";
import { AlertDialog, AlertDialogTrigger, Button, Tooltip } from "doowii-ui";
import { useNavigate } from "react-router-dom";

import { DeleteChatDialog } from "../ChatDataBoard";
import { LogoAvatar } from "../LogoAvatar";

interface PlainChatCardProps {
  chatId: string;
  message: string;
}
const PlainChatCard = ({ chatId, message }: PlainChatCardProps) => {
  const { deleteChatFromThread } = useChatData();
  const { _ } = useLingui();
  const { authUser } = useAuth();
  const navigate = useNavigate();
  const navigateToThreads = () => {
    navigate(`/${PATHS.USERS}/${authUser?.uid}/${PATHS.THREADS}`);
  };
  const handleChatDelete = async () => {
    await deleteChatFromThread(chatId, navigateToThreads);
  };
  return (
    <Flex
      align="center"
      className={css({
        p: "4",
        w: "full",
        marginX: "auto",
        bg: "base.white",
        rounded: "xl",
        border: "1px solid token(colors.base.lightGray)",
        shadow: "regular",
      })}
      direction="row"
      gap="4"
      justify="flex-start"
    >
      <LogoAvatar />

      <div className={css({ fontWeight: "medium", textWrap: "wrap", pl: "4", flexGrow: 1 })}>
        <MarkdownText markdownText={message} />
      </div>

      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Tooltip content={_(msg`Delete chat`)}>
            <Button arie-label={_(msg`Delete chat`)} iconOnly={faTrash} variant="dropdown" />
          </Tooltip>
        </AlertDialogTrigger>
        <DeleteChatDialog onConfirm={handleChatDelete} />
      </AlertDialog>
    </Flex>
  );
};

export { PlainChatCard };
