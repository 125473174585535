import React from "react";
import { Helmet } from "react-helmet-async";

interface Props {
  title: string;
  description?: string;
  children?: React.ReactNode;
}
const PageHeader: React.FC<Props> = ({ title: titleText, description, children }) => (
  <Helmet>
    <title>{titleText}</title>
    {description ? <meta content={description} name="description" /> : null}
    {children}
  </Helmet>
);

export { PageHeader };
