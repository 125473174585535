// ApolloDialog.tsx
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogBody,
  DialogFooter,
  Button,
  MainSearchBar,
  Callout,
} from "doowii-ui";
import { css } from "@styled-system/css";
import { HStack, Flex, Box } from "@styled-system/jsx";
import { useLingui } from "@lingui/react";
import { msg, Trans } from "@lingui/macro";
import { Result } from "@doowii-types/chat";
import { Pin } from "@doowii-types/index";
import { ParentDocTypeEnum } from "@api/retriever.i";
import { ApolloChatArea } from "./ApolloChatArea";
import { ApolloConversation } from "./ApolloConversation";
import { useChartConfig } from "@hooks/useChartConfig";
import { DynamicChartRenderer } from "@components/DynamicChartRenderer";
import ApolloGuide from "./ApolloGuide";

interface ApolloDialogProps {
  result: Result | Pin;
  parentDocId: string;
  parentDocType: ParentDocTypeEnum;
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
}

const ApolloDialog: React.FC<ApolloDialogProps> = ({
  result,
  parentDocId,
  parentDocType,
  setIsOpen,
  isOpen,
}) => {
  const [isAccoridonOpen, setIsAccordionOpen] = useState(false);
  const { _ } = useLingui();
  const { conversation, tempChartConfig, updateConfigState, handleChartConfigUpdate } =
    useChartConfig({
      initialConfig: result.chartConfig,
      chatId: result.id,
      parentId: parentDocId,
      parentType: parentDocType,
    });

  useEffect(() => {
    if (isOpen) {
      setIsAccordionOpen(false);
    }
  }, [isOpen]);

  const handleSave = async () => {
    await updateConfigState(tempChartConfig);
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <DialogContent aria-describedby={undefined} variant="fullScreen">
        <DialogHeader>
          <DialogTitle style={{ margin: "0", justifyContent: "start" }}>
            <Trans>Chart Wizard</Trans>
            <Box width="41%" marginLeft={"20px"}>
              <Callout variant="info" size="1">
                {_(
                  msg`You're among the first to try our new feature! It's in beta and improving every day!`
                )}
              </Callout>
            </Box>
          </DialogTitle>
        </DialogHeader>
        <DialogBody className={css({ width: "80vw", height: "80vh" })}>
          <Flex direction="row" gap="md" height="100%">
            {/* Left Side - DataContent */}
            <Box width="70%" height="100%">
              <DynamicChartRenderer
                currentResult={result}
                parentDocId={parentDocId}
                parentDocType={parentDocType}
                isModificationMode={true}
              />
            </Box>

            {/* Right Side */}
            <Box width="30%" display="flex" flexDirection="column" height="100%">
              {/* Chat Area */}
              <Box flex={isAccoridonOpen ? "5" : "1"} overflowY="auto">
                <ApolloGuide
                  result={result}
                  isAccordionOpen={isAccoridonOpen}
                  setIsAccordionOpen={setIsAccordionOpen}
                />
              </Box>

              <Box flex={isAccoridonOpen ? "3" : "7"} overflowY="auto" mt="md" mb="md">
                {" "}
                <ApolloChatArea>
                  <ApolloConversation conversation={conversation} />
                </ApolloChatArea>
              </Box>
              {/* Bottom Right - MainSearchBar */}
              <Box flexShrink={0}>
                <MainSearchBar
                  placeholder={_("Ask Doowii")}
                  label={_("Chat Input")}
                  onSubmit={(value) => handleChartConfigUpdate(value)}
                  size="small"
                />
              </Box>
            </Box>
          </Flex>
        </DialogBody>
        <DialogFooter>
          <HStack justifyContent="center" width="full" gap="md">
            <Button onClick={handleSave} variant="primary">
              <Trans>Save chart</Trans>
            </Button>
            <Button onClick={handleCancel} variant="secondary">
              <Trans>Cancel</Trans>
            </Button>
          </HStack>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export { ApolloDialog };
