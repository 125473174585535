export const VisualizationTypesEnum = {
  TABLE: "TABLE",
  PIE_CHART: "PIE",
  BAR_CHART: "BAR",
  LINE_CHART: "LINE",
  SAVANT_LINE: "SAVANT_LINE",
  SCATTER_CHART: "SCATTER",
  ERROR: "ERROR",
  NO_SQL: "NO_SQL",
  EMPTY_ROWS: "EMPTY_ROWS", // This is a legacy type from the old UI that we need to support, we can remove this once we have migrated all the old data
} as const;

export const DataVizTypes = {
  TABLE: "TABLE",
  PIE_CHART: "PIE",
  BAR_CHART: "BAR",
  LINE_CHART: "LINE",
  SAVANT_LINE: "SAVANT_LINE",
  ERROR: "ERROR",
  NO_SQL: "NO_SQL",
  SCATTER_CHART: "SCATTER",
  EMPTY_ROWS: "EMPTY_ROWS",
  SQL_CHART: "SQL_CHART",
} as const;

export type DataVizType = (typeof DataVizTypes)[keyof typeof DataVizTypes];
export type VisualizationType =
  (typeof VisualizationTypesEnum)[keyof typeof VisualizationTypesEnum];
