import {
  ChatLoading,
  ChatResultCard,
  PlainChatCard,
} from "@components/ChatContainer/ChatResultCard";
import { useChatData } from "@context/chat";
import { Result } from "@doowii-types/chat";
import { VisualizationTypesEnum } from "@doowii-types/viz";
import { Flex } from "@styled-system/jsx";
import { useEffect, useState } from "react";

import { ChatQuestionCard } from "./ChatQuestionCard";

interface ChatContainerProps {
  index: number;
  id: string;
  result: Result;
}

const ChatContainer = ({ id, index, result }: ChatContainerProps) => {
  const { answer } = useChatData();
  const [showResult, setShowResult] = useState(false);

  useEffect(() => {
    if (!result.loading) {
      const timer = setTimeout(() => {
        setShowResult(true);
      }, 300);
      return () => clearTimeout(timer);
    } else {
      setShowResult(false);
    }
  }, [result.loading]);

  return (
    <Flex direction="column" gap="4" id={id} w="full">
      <ChatQuestionCard result={result} />
      {result.loading && !showResult ? (
        <ChatLoading />
      ) : result.error && result.error.message ? (
        <PlainChatCard chatId={result.id} message={result.error.message} />
      ) : result.chartConfig.suggestion === VisualizationTypesEnum.NO_SQL ||
        result.chartConfig.suggestion === VisualizationTypesEnum.EMPTY_ROWS ? (
        <PlainChatCard chatId={result.id} message={answer[index]} />
      ) : (
        <ChatResultCard index={index} result={result} />
      )}
    </Flex>
  );
};

export { ChatContainer };
