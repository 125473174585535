import { Aggregations } from "@doowii-types/chart";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "doowii-ui";
import React from "react";

interface AggregationDropdownProps {
  aggregationType: Aggregations;
  onAggregationChange: (type: Aggregations) => void;
}

const AggregationDropdown: React.FC<AggregationDropdownProps> = ({
  aggregationType,
  onAggregationChange,
}) => {
  const { _ } = useLingui();
  const aggregationOptions: { label: string; value: Aggregations }[] = [
    { label: _(msg`Sum`), value: "sum" },
    { label: _(msg`Count`), value: "count" },
    { label: _(msg`Mean`), value: "mean" },
    { label: _(msg`Min`), value: "min" },
    { label: _(msg`Max`), value: "max" },
  ];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button iconRight={faChevronDown} size="xsmall" variant="dropdown">
          {aggregationType
            ? aggregationType.charAt(0).toUpperCase() + aggregationType.slice(1)
            : _(msg`Aggregate`)}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {aggregationOptions.map((option) => (
          <DropdownMenuItem key={option.value} onClick={() => onAggregationChange(option.value)}>
            {option.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export { AggregationDropdown };
