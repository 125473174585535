import { User } from "@firebase/auth";

const UserRoles = {
  Administrator: "Administrator",
  Researcher: "Researcher",
  Instructor: "Instructor",
  ITProfessional: "IT Professional",
  Other: "Other",
} as const;

type AuthUser = User;

export { UserRoles };
export type { AuthUser };

