import { PageHeader } from "@components/PageHeader";
import { PATHS } from "@constants/Paths";
import { useAuth } from "@hooks/useAuth";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { css } from "@styled-system/css";
import { Box } from "@styled-system/jsx";
import { flex } from "@styled-system/patterns";
import { BackgroundBox, Button, InputField, LoadingLogo, Logo, useToast } from "doowii-ui";
import { useEffect } from "react";
import { Form, Link, useActionData, useNavigate, useNavigation } from "react-router-dom";

import { ResetPasswordActionResponse } from "./ResetPasswordAction";

const ResetPassword = () => {
  const { _ } = useLingui();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const actionData = useActionData() as ResetPasswordActionResponse | null;
  const { isLoading, authUser } = useAuth();
  const { toast } = useToast();

  useEffect(() => {
    if (authUser) {
      navigate(PATHS.ROOT, { replace: true });
    }

    if (actionData?.success) {
      toast({
        status: "success",
        description: actionData.fields?.email,
      });
      setTimeout(() => {
        navigate(PATHS.SIGN_IN);
      }, 1500);
    }
  }, [actionData, authUser, navigate, toast]);

  const isSubmitting = navigation.state === "submitting";

  if (isLoading) {
    return <LoadingLogo />;
  }

  return (
    <>
      <PageHeader
        description={_(msg`Reset your password`)}
        title={_(msg`Doowii - Reset password`)}
      />
      <BackgroundBox
        containerProps={{ css: {} }}
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "100vh",
          padding: "xl6",
          opacity: isSubmitting ? 0.5 : 1,
          pointerEvents: isSubmitting ? "none" : "auto",
        }}
      >
        <Box
          className={flex({
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            flex: 1,
          })}
        >
          <Logo />
          <h2
            className={css({
              textStyle: "title_h2",
              fontWeight: 600,
              marginTop: "md",
              marginBottom: "xl",
            })}
          >
            <Trans>Reset your password</Trans>
          </h2>
          <Form className={css({ width: "100%", maxWidth: "400px" })} method="post">
            <Box
              className={flex({
                flexDirection: "column",
                gap: "ld",
              })}
            >
              <InputField
                disabled={isSubmitting}
                errorText={actionData?.errors?.email}
                label={_(msg`Your email`)}
                name="email"
                placeholder="name@school.edu"
                type="email"
              />
            </Box>
            <Button
              className={css({ marginTop: "xl3" })}
              disabled={isSubmitting}
              full
              loading={isSubmitting}
              type="submit"
            >
              <Trans>Send reset link</Trans>
            </Button>
          </Form>
        </Box>
        <Box
          className={css({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "foreground.muted",
            textStyle: "paragraph_14",
          })}
        >
          <Trans>Already have an account?</Trans>{" "}
          <Link
            className={css({
              color: "base.blueBrandPrimary",
              _hover: { textDecoration: "underline" },
            })}
            to={PATHS.SIGN_IN}
          >
            <Trans>Sign in</Trans>
          </Link>
        </Box>
      </BackgroundBox>
    </>
  );
};

export { ResetPassword };
