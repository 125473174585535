import { HIGHER_ED } from "@constants/constants";
import { getDocumentFromFirestore } from "@services/firebase/utils";

export const verifyEmailDomain = async (email: string): Promise<boolean> => {
  const higherEdDoc = await getDocumentFromFirestore("organizations", HIGHER_ED);
  const invitationList = higherEdDoc?.invitations;

  if (invitationList?.includes(email)) {
    return true;
  }

  const emailDomain = email.split("@")[1];
  if (emailDomain) {
    const allowedEmailDomains = [".edu", ".gov", ".org"];
    return allowedEmailDomains.some((domain) => emailDomain.endsWith(domain));
  }
  return false;
};
