import { useOrgUsers } from "@context/users";
import { useAuth } from "@hooks/useAuth";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { createInvitation } from "@services/firebase";
import { sendInvitationEmail } from "@services/webserver/emails";
import { css } from "@styled-system/css";
import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  InputField,
  Text,
  useToast,
} from "doowii-ui";
import { useState } from "react";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
const InviteUserDialog: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const { _ } = useLingui();
  const { toast } = useToast();
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const { userDocument } = useAuth();
  const { organizationUsers, disabledUsers, fetchUsersInOrganization } = useOrgUsers();

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/u;
    return re.test(email);
  };

  const handleSend = async () => {
    setLoading(true);
    if (!validateEmail(email)) {
      setErrorMessage(_(msg`Please enter a valid email address.`));
    } else if (organizationUsers.find((user) => user.email === email.toLowerCase())) {
      setErrorMessage(_(msg`User already exists in organization.`));
    } else if (disabledUsers.find((user) => user.email === email.toLowerCase())) {
      setErrorMessage(_(msg`User has been disabled.`));
    } else {
      try {
        await createInvitation(userDocument.organization, email.toLowerCase());
        await sendInvitationEmail(email, userDocument?.firstName ?? "");
        toast({
          title: _(msg`Invitation sent`),
          description: _(msg`Invitation has been sent successfully`),
          status: "success",
        });

        fetchUsersInOrganization();
      } catch (e) {
        toast({
          title: _(msg`Error sending invitation`),
          description: _(msg`An error occurred while sending the invitation.`),
          status: "error",
        });
      } finally {
        setIsOpen(false);
      }
    }
    setLoading(false);
  };

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <DialogContent aria-describedby={undefined}>
        <DialogHeader>
          <DialogTitle>
            <Trans>Invite more users</Trans>
          </DialogTitle>
        </DialogHeader>
        <DialogBody className={css({ display: "flex", flexDirection: "column", gap: "md" })}>
          <Text level={3}>
            <Trans>
              Invite others to leverage the power of AI-enabled analytics. Users will receive an
              email to create an account. At this time, users can access all data you have
              integrated with Doowii.
            </Trans>
          </Text>
          <InputField
            disabled={loading}
            errorText={errorMessage}
            label={_(msg`Email`)}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={_(msg`Enter email`)}
            type="email"
            value={email}
          />
        </DialogBody>
        <DialogFooter
          className={css({ borderTop: "none", justifyContent: "center!", p: "xl2", gap: "sm" })}
        >
          <Button loading={loading} onClick={handleSend} size="large" variant="primary">
            <Trans>Send</Trans>
          </Button>
          <Button
            disabled={loading}
            onClick={() => setIsOpen(false)}
            size="large"
            variant="secondary"
          >
            <Trans>Cancel</Trans>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default InviteUserDialog;
