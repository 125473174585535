import { ColumnDef, RowData } from "@tanstack/react-table";

type Rows = RowData[];

type Columns<T extends RowData> = ColumnDef<T>[];

type RowCount = number;

type DataTypes = Record<
  string,
  ["timestamp" | "datetime" | "date" | "string" | "integar" | "boolean" | "float"]
>;

enum ParentDocTypeEnum {
  THREAD = "thread",
  PINBOARD = "pinboard",
}

export { ParentDocTypeEnum };
export type { Columns, DataTypes, RowCount, Rows };

