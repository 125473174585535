// src/services/webserver/featureFlags.ts
import { operations } from "@doowii-types/WebserverInterfaces.gen";
import { AxiosResponse } from "axios";

import { get } from "./base";

type FeatureFlagsResponse =
  operations["provisioned_flags_api_feature_flags_get"]["responses"]["200"]["content"]["application/json"];
export const fetchFeatureFlags = async (): Promise<FeatureFlagsResponse | null> => {
  try {
    const response: AxiosResponse<FeatureFlagsResponse> = await get(`/feature_flags`);
    return response.data;
  } catch (error) {
    console.error("Error fetching feature flags", error);
    return null;
  }
};
