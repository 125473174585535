import { usePinboard } from "@context/pinboard";
import { Board } from "@doowii-types/pinboard";
import { Flex } from "@doowii/design-system/jsx";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { PinboardsView } from "@routes/Pinboards";
import { css } from "@styled-system/css";
import { Box } from "@styled-system/jsx";
import { stopPropagationWrapper } from "@utils/stopPropagationWrapper";
import { Button, CircularCounter, Heading, Text, Tooltip } from "doowii-ui";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Doowii } from "../../search/Doowii";
import PinboardCardDropdown from "./components/PinboardCardDropdown";
import PinboardCardImage from "./components/PinboardCardImage";
import { PinboardCardSuggestions } from "./components/PinboardCardSuggestions";

const ElipsisTextStyles = {
  display: "-webkit-box",
  overflow: "hidden",
  "-webkit-line-clamp": 2,
  "-webkit-box-orient": "vertical",
};

interface Props {
  view: PinboardsView;
  board: Board;
  doowii: Doowii;
}
const PinboardCard: React.FC<Props> = ({ view, board, doowii }) => {
  const { _ } = useLingui();
  const { setCurrBoardId } = usePinboard();
  const navigate = useNavigate();
  const [showSuggestion, setShowSuggestion] = useState(false);
  const isGrid = view === "grid";

  return (
    <Box
      aria-label={_(msg`Open board ${board.name}`)}
      className={css({
        flexDirection: "column",
        position: "relative",
        p: "ld",
        pt: "md",
        bg: "white",
        maxW: isGrid ? 534 : "auto",
        maxH: ["auto", isGrid ? 470 : "auto"],
        borderRadius: "xl",
        boxShadow: "regular",
        gap: "ld",
        cursor: "pointer",
      })}
      onClick={() => {
        setCurrBoardId(board.id);
        // Avoid blinking when navigating
        setTimeout(() => {
          navigate(`${board.id}`);
        }, 100);
      }}
    >
      <Flex
        css={{
          flexDirection: isGrid ? "column" : "row",
          gap: "ld",
          alignItems: isGrid ? "" : "center",
          justifyContent: "flex-start",
        }}
      >
        <Flex css={{ flexDirection: "column", gap: "xs", order: isGrid ? 1 : 2, flex: 1 }}>
          <Flex css={{ justifyContent: "space-between", alignItems: "center" }}>
            <Tooltip content={board.name}>
              <Box>
                <Heading css={ElipsisTextStyles} level={2}>
                  {board.name}
                </Heading>
              </Box>
            </Tooltip>
            <Box>
              <PinboardCardDropdown board={board} />
            </Box>
          </Flex>

          <Flex
            css={{ alignItems: ["start", "center"], gap: "sm", flexDirection: ["column", "row"] }}
          >
            <Flex alignItems="center" gap="sm">
              <Box>
                <CircularCounter counter={board.pins.length || 0} />
              </Box>
              <Text level={3}>
                <Trans>Responses pinned to this board</Trans>
              </Text>
            </Flex>
            <Button
              onClick={stopPropagationWrapper(() => setShowSuggestion((prev) => !prev))}
              variant="text"
            >
              {showSuggestion ? <Trans>Hide suggestions</Trans> : <Trans>See suggestions</Trans>}
            </Button>
          </Flex>
        </Flex>
        {showSuggestion && isGrid ? (
          <Flex css={{ flexDirection: "column", gap: "sm", order: 2 }}>
            <PinboardCardSuggestions doowii={doowii} />
          </Flex>
        ) : (
          <Box css={{ order: isGrid ? 2 : 1 }}>
            {/* TODO: Replace with real image once it is available */}
            <PinboardCardImage imgUrl="https://github.com/shadcn.png" isGrid={isGrid} />
          </Box>
        )}
      </Flex>

      {showSuggestion && !isGrid ? (
        <Flex css={{ flexDirection: "column", gap: "sm", order: 2 }}>
          <PinboardCardSuggestions doowii={doowii} />
        </Flex>
      ) : null}
    </Box>
  );
};

export { PinboardCard };
