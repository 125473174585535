import { useEffect, useRef } from "react";

export const useModalClose = (isOpen: boolean, triggerId: string) => {
  const openedRef = useRef(false);
  useEffect(() => {
    if (isOpen && !openedRef.current) {
      openedRef.current = true;
    }

    if (!isOpen && openedRef.current) {
      document.getElementById(triggerId)?.focus();
    }
  }, [isOpen, triggerId]);
};
