import { PATHS } from "@constants/Paths";
import { useChatData } from "@context/chat";
import { faBolt } from "@fortawesome/pro-regular-svg-icons";
import { useAuth } from "@hooks/useAuth";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Flex } from "@styled-system/jsx";
import { CircularIcon, Heading, Skeleton, SuggestionCard, Text } from "doowii-ui";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Doowii } from "../../search/Doowii";
import { QuestionTypeEnum } from "../../search/Doowii.i";

const PinboardSuggestionCard = () => {
  const { i18n } = useLingui();
  const { userDocument, authUser } = useAuth();
  const navigate = useNavigate();
  const { _ } = useLingui();
  const {
    dynamicCache,
    fetchingSuggestions,
    setLoading,
    setAllResults,
    setAnswer,
    setStreamLoading,
    newChat,
    currentThread,
    allResults,
    threads,
  } = useChatData();

  const doowii = new Doowii(
    i18n,
    setLoading,
    setAllResults,
    setAnswer,
    setStreamLoading,
    userDocument,
    currentThread,
    allResults,
    threads
  );

  useEffect(() => {
    newChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHandleSuggestionClick = async (suggestion: string) => {
    navigate(`/${PATHS.USERS}/${authUser?.uid}/${PATHS.THREADS}/${currentThread}`);
    await doowii.chat({
      query: suggestion,
      index: 0,
      questionType: QuestionTypeEnum.SUGGESTION,
    });
  };

  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "xl4",
        bg: "white",
        borderRadius: "xl",
        p: "xl",
        shadow: "regular",
        border: "2px dashed token(colors.border.secondary)",
      }}
    >
      <Flex css={{ flexDirection: "column", alignItems: "center", gap: "ld" }}>
        <CircularIcon icon={faBolt} />
        <Flex css={{ flexDirection: "column", alignItems: "center", gap: "sm" }}>
          <Heading level={3}>
            <Trans>Quickly create your first pin</Trans>
          </Heading>
          <Text level={3}>
            <Trans>Ask doowii one of the suggested questions below</Trans>
          </Text>
        </Flex>
      </Flex>
      <Flex css={{ flexDirection: "column", gap: "sm" }}>
        {fetchingSuggestions ? (
          <>
            <Skeleton height="92px" w="full" />
            <Skeleton height="92px" w="full" />
            <Skeleton height="92px" w="full" />
          </>
        ) : (
          dynamicCache.slice(1, 4).map((suggestion, index) => (
            <SuggestionCard
              key={index}
              label={_(msg`Start new chat with suggestion: ${suggestion}`)}
              onClick={() => onHandleSuggestionClick(suggestion)}
            >
              {suggestion}
            </SuggestionCard>
          ))
        )}
      </Flex>
    </Flex>
  );
};

export { PinboardSuggestionCard };
