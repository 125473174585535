import { useChatData } from "@context/chat";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Box } from "@styled-system/jsx";
import {
  Breadcrumb as BreadcrumbContainer,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  Tooltip,
} from "doowii-ui";
import { Link, Params, useMatches, useParams } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

export interface BreadcrumbProps {
  text: string;
  link?: (params: Readonly<Params>) => string;
  dynamicText?: (params: Readonly<Params>) => string;
}

const Breadcrumb = () => {
  const { _ } = useLingui();
  const params = useParams();
  const matches = useMatches();
  const { loading, streamLoading } = useChatData();
  const disableNavigation = loading || streamLoading;

  const [crumbs] = matches
    .filter((match) =>
      Boolean((match as { handle?: { crumbs: BreadcrumbProps[] } }).handle?.crumbs)
    )
    .map((match) => (match as { handle?: { crumbs: BreadcrumbProps[] } }).handle?.crumbs);

  if (!crumbs) {
    return <Box />;
  }

  return (
    <BreadcrumbContainer>
      <BreadcrumbList>
        {crumbs.map((crumb, index) => (
          <Fragment key={index}>
            <BreadcrumbItem aria-label={_(msg`${crumb.text} - breadcrumb`)}>
              {crumb.link && !disableNavigation ? (
                <Tooltip content={crumb.text}>
                  <BreadcrumbLink asChild>
                    <Link to={crumb.link(params)}>{crumb.text}</Link>
                  </BreadcrumbLink>
                </Tooltip>
              ) : crumb.dynamicText ? (
                crumb.dynamicText(params)
              ) : (
                crumb.text
              )}
            </BreadcrumbItem>

            {index < crumbs.length - 1 ? <BreadcrumbSeparator /> : null}
          </Fragment>
        ))}
      </BreadcrumbList>
    </BreadcrumbContainer>
  );
};

export default Breadcrumb;
