import { Result } from "@doowii-types/chat";

interface RecentMessagesProps {
  allResults: Result[];
  answer: string[];
}

export const getRecentMessages = ({ allResults, answer }: RecentMessagesProps) => {
  if (allResults && answer && allResults.length === answer.length) {
    return allResults.slice(-5).map((result, index) => {
      let answerIndex;
      if (allResults.length <= 5) {
        answerIndex = index;
      } else {
        answerIndex = allResults.length - 5 + index;
      }

      return {
        query: result.query,
        sql: result.sql || "",
        answer: answer[answerIndex] || "",
      };
    });
  }

  return [];
};
