import { Flex } from "@styled-system/jsx";
import { Button, Heading, Text } from "doowii-ui";
import React, { useEffect } from "react";
import { useNavigate, useRouteError } from "react-router-dom";
import * as Sentry from "@sentry/react";

const ErrorPage: React.FC = () => {
  const navigate = useNavigate();
  const error = useRouteError();

  useEffect(() => {
    if (error) {
      Sentry.withScope((scope) => {
        scope.setTag("log-tag", "frontend-error");
        scope.setLevel("error");
        Sentry.captureException(error); // Capture the error with Sentry
      });
    }
  }, [error]);

  return (
    <Flex
      css={{
        backgroundImage: `url(/error.webp)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100vw",
        height: "100vh",
      }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Flex css={{ marginLeft: "54px" }} alignItems={"center"} direction={"column"} gap={"md"}>
        <Heading level={1}>Oops!</Heading>
        <Text level={3}>Looks like we are experiencing some difficulties.</Text>
        <Text>Don't worry the doowers are on it! </Text>
        <Button onClick={() => navigate("/")}>Return to Home</Button>
      </Flex>
    </Flex>
  );
};

export { ErrorPage };
