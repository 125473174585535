import { RouterProvider } from "react-router-dom";

import useRouter from "./useRouter";

const RouterWrapper = () => {
  const router = useRouter();
  return <RouterProvider router={router} />;
};

export default RouterWrapper;
