import { usePinboard } from "@context/pinboard";
import { useModalClose } from "@hooks/useModalClose";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { css } from "@styled-system/css";
import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  InputField,
} from "doowii-ui";
import { useEffect, useState } from "react";

interface Props {
  isOpen: boolean;
  pinboardId: string;
  pinId: string;
  pinTitle: string;
  triggerId: string;
  setIsOpen: (isOpen: boolean) => void;
}
const EditPinTitleDialog: React.FC<Props> = ({
  isOpen,
  pinboardId,
  pinId,
  pinTitle,
  triggerId,
  setIsOpen,
}) => {
  useModalClose(isOpen, triggerId);
  const { _ } = useLingui();
  const [name, setName] = useState(pinTitle);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { updatePinTitle } = usePinboard();

  const handleUpdatePinTitle = async () => {
    setLoading(true);
    if (name.trim().length < 1) {
      setErrorMessage(_(msg`Pinboard name cannot be empty`));
    } else {
      await updatePinTitle(pinboardId, pinId, name);
      setIsOpen(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    setName(pinTitle);
  }, [isOpen, pinTitle]);

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <DialogContent aria-describedby={undefined}>
        <DialogHeader>
          <DialogTitle>
            <Trans>Edit pin title</Trans>
          </DialogTitle>
        </DialogHeader>
        <DialogBody className={css({ display: "flex", flexDirection: "column", gap: "md" })}>
          <InputField
            disabled={loading}
            errorText={errorMessage}
            label={_(msg`Pin title`)}
            onChange={(e) => setName(e.target.value)}
            placeholder={_(msg`Enter pinboard name`)}
            type="text"
            value={name}
          />
        </DialogBody>
        <DialogFooter className={css({ borderTop: "none", p: "xl2", gap: "sm" })}>
          <Button loading={loading} onClick={handleUpdatePinTitle} size="large" variant="primary">
            <Trans>Save</Trans>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export { EditPinTitleDialog };
