import { useDebounce } from "@uidotdev/usehooks";
import { useEffect, useRef, useState } from "react";

export const useResize = () => {
  const [height, setHeight] = useState<number | null>(null);;
  const [width, setWidth] = useState<number | null>(null);;
  const [entries, setEntries] = useState<ResizeObserverEntry[]>([]);

  const componentRef = useRef(null);
  const debouncedEntries = useDebounce(entries, 100);

  useEffect(() => {
    if (!debouncedEntries.length) {
      return;
    }

    for (const entry of debouncedEntries) {
      setHeight(entry.contentRect.height);
      setWidth(entry.contentRect.width);
    }
  }, [debouncedEntries]);

  useEffect(() => {
    if (componentRef.current) {
      const resizeObserver = new ResizeObserver(setEntries);
      resizeObserver.observe(componentRef.current);

      return () => resizeObserver.disconnect();
    }
  }, []);

  return { height, width, componentRef };
};
