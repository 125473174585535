import { redirect } from "react-router-dom";

import { useBoundStore } from "../../stores/store";

const signOutAction = async () => {
  await useBoundStore.getState().signOut();
  return redirect("/");
};

export { signOutAction };
