import Highcharts from "highcharts";
import HighchartsAccessibility from "highcharts/modules/accessibility";

// Initialize the accessibility module
HighchartsAccessibility(Highcharts);

const sharedChartOptions: Highcharts.Options = {
  chart: {
    zooming: {
      type: "xy" as const,
    },
    panning: {
      enabled: true,
      type: "xy" as const,
    },
    panKey: "shift" as const,
  },
};

export { Highcharts as HighchartsWithAccessibility, sharedChartOptions };
