import { useAuth } from "@hooks/useAuth";
import { useLingui } from "@lingui/react";
import React, { createContext, PropsWithChildren, useContext, useMemo, useState } from "react";

import { Doowii } from "../../search/Doowii";
import { useChatData } from "../chat";

interface DoowiiContextType {
  doowii: Doowii;
  loadingText: string;
  setLoadingText: React.Dispatch<React.SetStateAction<string>>;
}

const DoowiiContext = createContext<DoowiiContextType | undefined>(undefined);

export const DoowiiProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [loadingText, setLoadingText] = useState("");

  const { userDocument } = useAuth();
  const {
    setCurrentResult: setResults,
    setSearchHistory,
    allResults,
    setAllResults,
    setAnswer,
    setLoading,
    setStreamLoading,
    currentThread,
    threads,
  } = useChatData();

  const { i18n } = useLingui();

  const doowii = useMemo(
    () =>
      new Doowii(
        i18n,
        setLoading,
        setAllResults,
        setAnswer,
        setStreamLoading,
        userDocument,
        currentThread,
        allResults,
        threads
      ),
    [
      i18n,
      setLoading,
      setAllResults,
      setAnswer,
      setStreamLoading,
      userDocument,
      currentThread,
      allResults,
      threads,
    ]
  );

  return (
    <DoowiiContext.Provider value={{ doowii, loadingText, setLoadingText }}>
      {children}
    </DoowiiContext.Provider>
  );
};

export const useDoowii = (): DoowiiContextType => {
  const context = useContext(DoowiiContext);
  if (context === undefined) {
    throw new Error("useDoowii must be used within a DoowiiProvider");
  }
  return context;
};
