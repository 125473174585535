import { components, operations } from "@doowii-types/WebserverInterfaces.gen";
import * as Sentry from "@sentry/react";

import { post } from "./base";

type FetchEmbeddingsRequest = components["schemas"]["Question"];

type FetchEmbeddingsResponse =
  operations["fetch_embeddings_from_openai_api_embeddings_post"]["responses"]["200"]["content"]["application/json"];

type FetchTitleRequest = components["schemas"]["Questions"];

type FetchTitleResponse =
  operations["fetch_title_api_fetch_title_post"]["responses"]["200"]["content"]["application/json"];

type GenerateChartConfigRequest = components["schemas"]["ChartConfigRequest"];

type GenerateChartConfigResponse =
  operations["generate_chart_config_api_generate_chart_config_post"]["responses"]["200"]["content"]["application/json"];

export const fetchEmbeddings = async (
  question: FetchEmbeddingsRequest
): Promise<FetchEmbeddingsResponse | undefined> => {
  try {
    const response = await post("/embeddings", question);
    return response.data;
  } catch (error) {
    console.error("Error fetching embeddings", error);
    Sentry.withScope((scope) => {
      scope.setLevel("warning");
      Sentry.captureException(error);
    });
  }
};

export const fetchTitleFromServer = async (
  questions: FetchTitleRequest
): Promise<FetchTitleResponse | undefined> => {
  try {
    const response = await post("/fetch_title", questions);
    return response.data;
  } catch (error) {
    console.error("Error fetching thread title", error);

    // Set the level to warning to avoid reporting this low-urgency error to PagerDuty
    Sentry.withScope((scope) => {
      scope.setLevel("warning");
      Sentry.captureException(error);
    });
  }
};

export const getNewChartConfig = async (
  sql: string,
  userRequest: string
): Promise<GenerateChartConfigResponse | undefined> => {
  try {
    const request: GenerateChartConfigRequest = {
      sql_query: sql,
      user_request: userRequest,
    };
    const response = await post("/generate_chart_config", request);
    return response.data;
  } catch (error) {
    console.error("Error generating new chart config", error);
    Sentry.captureException(error);
  }
};
