import { components, operations } from "@doowii-types/WebserverInterfaces.gen";
import { AxiosResponse } from "axios";

import { post } from "./base";

type MarketingOptInRequest = components["schemas"]["MarketingOptInRequest"];

type ExportSuccessResponse =
  operations["export_marketing_opt_in_users_api_sheets_export_marketing_opt_in_users_post"]["responses"]["200"]["content"]["application/json"];

type ExportErrorResponse =
  operations["export_marketing_opt_in_users_api_sheets_export_marketing_opt_in_users_post"]["responses"]["500"]["content"]["application/json"];

export const exportMarketingOptInUsers = async (
  request: MarketingOptInRequest
): Promise<AxiosResponse<ExportSuccessResponse> | ExportErrorResponse | undefined> => {
  try {
    return await post("/sheets/export-marketing-opt-in-users", request);
  } catch (error) {
    console.error("Error exporting marketing opt-in users", error);
  }
};
