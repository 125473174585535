import { doc, DocumentData, getDoc, setDoc } from "firebase/firestore";

import { withSentry } from "../../utils/wrapper";
import { db } from "./connection";

const getDocumentFromFirestore = withSentry(async (path: string, id: string) => {
  const docRef = doc(db, path, id);
  const docSnap = await getDoc(docRef);
  return docSnap.exists() ? docSnap.data() : null;
});

const updateDocumentInFirestore = withSentry(
  (path: string, id: string, data: DocumentData, shouldMerge: boolean = true) => {
    const docRef = doc(db, path, id);
    return setDoc(docRef, data, { merge: shouldMerge });
  }
);

export { getDocumentFromFirestore, updateDocumentInFirestore };
