import { ChartAPIResponse, ChartConfig } from '@doowii-types/chart';
import { post } from './base'; // Import the post function from your base API file


/**
 * Makes a request to the modify_chart endpoint with the user's request and sanitized chart config.
 * @param userRequest - The user's modification request in plain English.
 * @param currentChartConfig - The current chart configuration to be modified.
 * @returns The modified chart configuration and additional info from the API.
 */
export async function modifyChart(
  userRequest: string,
  currentChartConfig: ChartConfig
): Promise<ChartAPIResponse> {
  // Sanitize the current chart config
  const sanitizedChartConfig = sanitizeChartConfig(currentChartConfig);

  const requestData = {
    user_request: userRequest,
    current_chart_config: sanitizedChartConfig,
  };

  try {
    const response = await post('/modify_chart', requestData);
    return response.data as ChartAPIResponse;
  } catch (error) {
    console.error('Error modifying chart:', error);
    throw error;
  }
}

/**
 * Sanitizes the chart configuration by replacing data points and sensitive fields with placeholders.
 * @param chartConfig - The chart configuration to sanitize.
 * @returns The sanitized chart configuration.
 */
function sanitizeChartConfig(chartConfig: ChartConfig): ChartConfig {
  // Deep copy to avoid mutating the original object
  const sanitizedConfig = JSON.parse(JSON.stringify(chartConfig));

  // Replace height and width with placeholders
  if (sanitizedConfig.options && sanitizedConfig.options.config) {
    sanitizedConfig.options.config = replacePlaceholdersInConfig(sanitizedConfig.options.config);
  }

  return sanitizedConfig;
}

/**
 * Recursively replaces data points, height, width, and other sensitive fields with placeholders.
 * @param config - The config object to process.
 * @returns The config object with placeholders.
 */
function replacePlaceholdersInConfig(config: any): any {
  if (typeof config === 'object' && config !== null) {
    for (const key in config) {
      if (key === 'series') {
        // Replace data arrays with placeholder
        config[key] = '{{series}}';
      } else if (key === 'height') {
        config[key] = '{{height}}';
      } else if (key === 'width') {
        config[key] = '{{width}}';
      } else if (key === 'categories') {
        config[key] = '{{categories}}';
      }else if (key === 'labelFormatter' && typeof config[key] === 'function') {
        // Convert functions to string representation
        config[key] = config[key].toString();
      } else if (typeof config[key] === 'object') {
        // Recursively process nested objects
        config[key] = replacePlaceholdersInConfig(config[key]);
      }
    }
  }
  return config;
}
