const LogoAvatar = () => (
  <div>
    <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
      <rect
        height="30"
        rx="15"
        stroke="url(#paint0_linear_329_5667)"
        strokeWidth="2"
        width="30"
        x="1"
        y="1"
      />
      <path
        d="M12.8875 23.5C11.9388 23.5 11.0933 23.2683 10.3509 22.805C9.62228 22.3417 9.04486 21.7099 8.61867 20.9096C8.20622 20.1094 8 19.1968 8 18.1719C8 17.147 8.20622 16.2344 8.61867 15.4341C9.04486 14.6338 9.62228 14.002 10.3509 13.5387C11.0933 13.0754 11.9388 12.8437 12.8875 12.8437C13.5749 12.8437 14.1936 12.9771 14.7435 13.2439C15.3072 13.5106 15.7677 13.8827 16.1252 14.3601C16.4964 14.8234 16.7026 15.3569 16.7438 15.9606V20.2779C16.7026 20.8816 16.5032 21.4291 16.1458 21.9205C15.7883 22.3979 15.3278 22.784 14.7641 23.0788C14.2004 23.3596 13.5749 23.5 12.8875 23.5ZM13.4443 20.5938C13.898 20.5938 14.2898 20.4955 14.6197 20.2989C14.9497 20.0883 15.2109 19.8005 15.4034 19.4355C15.5959 19.0704 15.6921 18.6492 15.6921 18.1719C15.6921 17.6945 15.5959 17.2803 15.4034 16.9293C15.2247 16.5643 14.9634 16.2765 14.6197 16.0659C14.2898 15.8553 13.9048 15.75 13.4649 15.75C13.025 15.75 12.6331 15.8553 12.2894 16.0659C11.9595 16.2765 11.6914 16.5643 11.4852 16.9293C11.2927 17.2944 11.1964 17.7086 11.1964 18.1719C11.1964 18.6352 11.2927 19.0494 11.4852 19.4144C11.6776 19.7794 11.9457 20.0673 12.2894 20.2779C12.6331 20.4885 13.0181 20.5938 13.4443 20.5938ZM18.6617 23.2894H15.5684V20.5306L16.0427 18.0455L15.5065 15.5605V8H18.6617V23.2894Z"
        fill="#153946"
      />
      <path
        d="M22.309 23.2989C21.814 23.2989 21.4016 23.1305 21.0716 22.7935C20.7554 22.4425 20.5973 22.0213 20.5973 21.5299C20.5973 21.0245 20.7554 20.6033 21.0716 20.2663C21.4016 19.9294 21.814 19.7609 22.309 19.7609C22.8039 19.7609 23.2095 19.9294 23.5257 20.2663C23.8419 20.6033 24 21.0245 24 21.5299C24 22.0213 23.8419 22.4425 23.5257 22.7935C23.2095 23.1305 22.8039 23.2989 22.309 23.2989Z"
        fill="#153946"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_329_5667"
          x1="17.1586"
          x2="23.6878"
          y1="9.23381e-06"
          y2="0.8623"
        >
          <stop offset="0.0162358" stopColor="#29BDF0" />
          <stop offset="1" stopColor="#F6CD3C" />
        </linearGradient>
      </defs>
    </svg>
  </div>
);

export { LogoAvatar };
