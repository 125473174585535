import { ParentDocTypeEnum } from "@api/retriever.i";
import { DataNavigator } from "@components/DataNavigator";
import { ShareResultDialog } from "@components/ShareResultDialog";
import { SQLSidePanel } from "@components/SQLSidePanel";
import { useChatData } from "@context/chat";
import type { Result } from "@doowii-types/chat";
import { css, cx } from "@styled-system/css";
import { HStack } from "@styled-system/jsx";
import { flex } from "@styled-system/patterns";

import { ChatMenu } from "./ChatMenu";
import { ApolloDialog } from "@components/Apollo/ApolloDialog";
import { DynamicChartRenderer } from "@components/DynamicChartRenderer";

interface ChatDataBoardProps {
  result: Result;
  setIsShareResultDialogOpen: (isOpen: boolean) => void;
  isShareResultDialogOpen: boolean;
  isApolloDialogOpen: boolean;
  setIsApolloDialogOpen: (isOpen: boolean) => void;
}

const ChatDataBoard = ({
  result,
  setIsShareResultDialogOpen,
  isShareResultDialogOpen,
  isApolloDialogOpen,
  setIsApolloDialogOpen,
}: ChatDataBoardProps) => {
  const { currentThread } = useChatData();

  return (
    <div
      className={cx(
        flex({
          direction: "column",
        })
      )}
    >
      <div
        className={cx(
          flex({
            direction: "row",
            justify: "space-between",
            align: "center",
          }),
          css({
            h: "16",
            p: "4",
          })
        )}
      >
        <DataNavigator
          currentResult={result}
          parentDocId={currentThread}
          parentDocType={ParentDocTypeEnum.THREAD}
          setIsApolloDialogOpen={setIsApolloDialogOpen}
        />
        <HStack gap="none">
          <SQLSidePanel
            chatId={result.id}
            originalSql={result.originalSql ?? result.sql}
            sql={result.sql}
          />
          <ChatMenu chatId={result.id} title={result.title} />
        </HStack>
      </div>

      <div className={css({ w: "full", maxHeight: "3xl", overflowY: "auto" })}>
        <DynamicChartRenderer
          currentResult={result}
          parentDocId={currentThread}
          parentDocType={ParentDocTypeEnum.THREAD}
        />
      </div>
      <ShareResultDialog
        isOpen={isShareResultDialogOpen}
        parentDocId={currentThread}
        parentDocType={ParentDocTypeEnum.THREAD}
        result={result}
        setIsShareResultDialogOpen={setIsShareResultDialogOpen}
      />
      <ApolloDialog
        isOpen={isApolloDialogOpen}
        parentDocId={currentThread}
        parentDocType={ParentDocTypeEnum.THREAD}
        result={result}
        setIsOpen={setIsApolloDialogOpen}
      />
    </div>
  );
};

export { ChatDataBoard };
