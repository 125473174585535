import { MarkdownText } from "@components/MarkdownText";
import { css } from "@styled-system/css";

import { ChatExplanationSkeleton } from "./ChatExplanationSkeleton";

interface ChatExplanationProps {
  explanation: string;
}

const ChatExplanation = ({ explanation }: ChatExplanationProps) => (
  <div
    className={css({
      px: "4",
      py: "6",
    })}
  >
    {explanation.length === 0 ? (
      <ChatExplanationSkeleton />
    ) : (
      <MarkdownText markdownText={explanation} />
    )}
  </div>
);

export { ChatExplanation };
