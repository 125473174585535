import { PATHS } from "@constants/Paths";
import { usePinboard } from "@context/pinboard";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { useAuth } from "@hooks/useAuth";
import { useModalClose } from "@hooks/useModalClose";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { stopPropagationWrapper } from "@utils/stopPropagationWrapper";
import { ConfirmationDialog } from "doowii-ui";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  isOpen: boolean;
  boardId: string;
  triggerId: string;
  setIsOpen: (isOpen: boolean) => void;
}
const DeletePinboardDialog: React.FC<Props> = ({ isOpen, boardId, triggerId, setIsOpen }) => {
  useModalClose(isOpen, triggerId);
  const { _ } = useLingui();
  const navigate = useNavigate();
  const { authUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const { deleteBoard } = usePinboard();
  const handleDelete = async () => {
    setLoading(true);
    await deleteBoard(boardId);
    setIsOpen(false);
    setLoading(false);
    navigate(`/${PATHS.USERS}/${authUser?.uid}/${PATHS.PINBOARDS}`);
  };

  return (
    <ConfirmationDialog
      cancelButtonText={_(msg`Cancel`)}
      confirmationButtonText={_(msg`Delete`)}
      description={_(msg`Are you sure you want to delete this pinboard?`)}
      destructive
      icon={faTrash}
      isLoadingAction={loading}
      isOpen={isOpen}
      onCancel={stopPropagationWrapper(() => setIsOpen(false))}
      onConfirm={stopPropagationWrapper(handleDelete)}
      setIsOpen={setIsOpen}
      title={_(msg`Delete pinboard`)}
    />
  );
};

export { DeletePinboardDialog };
