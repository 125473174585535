import { ParentDocTypeEnum } from "@api/retriever.i";
import { DataNavigator } from "@components/DataNavigator";
import { DynamicChartRenderer } from "@components/DynamicChartRenderer";
import { usePinboard } from "@context/pinboard";
import { Pin } from "@doowii-types/pinboard";
import { css, cx } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

interface FullScreenChatDataBoardProps {
  pin: Pin;
}

const FullScreenChatDataBoard = ({ pin }: FullScreenChatDataBoardProps) => {
  const { currBoardId } = usePinboard();

  return (
    <div
      className={cx(
        flex({
          direction: "column",
        })
      )}
    >
      <div
        className={cx(
          flex({
            direction: "row",
            justify: "space-between",
            align: "center",
          }),
          css({
            h: "16",
            p: "4",
          })
        )}
      >
        <DataNavigator
          currentResult={pin}
          parentDocId={currBoardId}
          parentDocType={ParentDocTypeEnum.PINBOARD}
          setIsApolloDialogOpen={() => {}}
        />
      </div>
      <div>
        <DynamicChartRenderer
          currentResult={pin}
          parentDocId={currBoardId}
          parentDocType={ParentDocTypeEnum.PINBOARD}
        />
      </div>
    </div>
  );
};

export { FullScreenChatDataBoard };
