import { ChatContainer } from "@components/ChatContainer";
import { ChatSearchBar } from "@components/ChatSearchBar";
import { PageHeader } from "@components/PageHeader";
import { SuggestionsCardsContainer } from "@components/SuggestionsCardsContainer";
import { useChatData } from "@context/chat";
import { useAuth } from "@hooks/useAuth";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { css } from "@styled-system/css";
import { Box, Flex } from "@styled-system/jsx";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Doowii } from "../../../search/Doowii";

const Thread = () => {
  const { _, i18n } = useLingui();
  const { userDocument } = useAuth();
  const {
    allResults,
    setAllResults,
    setAnswer,
    setLoading,
    setStreamLoading,
    currentThread,
    threads,
  } = useChatData();
  const { hash } = useLocation();

  const doowii = new Doowii(
    i18n,
    setLoading,
    setAllResults,
    setAnswer,
    setStreamLoading,
    userDocument,
    currentThread,
    allResults,
    threads
  );

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const elementId = hash.replace("#", "");
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 100);
    }
  }, [hash]);

  const currentThreadName = useMemo(
    () => threads.find((thread) => thread.id === currentThread)?.title || "New chat",
    [currentThread, threads]
  );

  const hasChats = allResults.length !== 0;

  return (
    <>
      <PageHeader
        description={_(msg`Page to list threads`)}
        title={_(msg`Doowii - Thread: ${currentThreadName}`)}
      />

      <Flex
        className={css({
          w: "full",
          minHeight: "100vh",
        })}
        direction="column"
        justify="space-between"
      >
        <Flex
          css={{
            height: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: hasChats ? "auto" : "center",
            gap: "4",
            pb: "10",
            px: "10",
          }}
        >
          {hasChats ? (
            allResults.map((result, index) => (
              <ChatContainer
                id={result.id}
                index={index}
                key={`index-${result.id}`}
                result={result}
              />
            ))
          ) : (
            <SuggestionsCardsContainer doowii={doowii} homePage={false} />
          )}
        </Flex>

        <Box
          className={css({
            position: "sticky",
            bottom: 0,
            zIndex: 10,
          })}
        >
          <Box
            className={css({
              backgroundColor: "base.white",
            })}
          >
            <ChatSearchBar doowii={doowii} />
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export { Thread };
