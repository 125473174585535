import { PageHeader } from "@components/PageHeader";
import { DeletePinboardDialog, EditPinboardNameDialog } from "@components/PinboardCard";
import { PinboardResultCard } from "@components/PinboardResultCard";
import { PinboardSuggestionCard } from "@components/PinboardSuggestionCard/PinboardSuggestionCard";
import { usePinboard } from "@context/pinboard";
import { faPen, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Flex, Grid, HStack } from "@styled-system/jsx";
import { Button, Heading, LoadingLogo, SearchInput, Tooltip } from "doowii-ui";
import { useId, useState } from "react";

const Pinboard = () => {
  const id = useId();
  const { _ } = useLingui();
  const { pinboardResults, loading, currBoardId, boards } = usePinboard();
  const [searchQuery, setSearchQuery] = useState("");

  const boardName = boards.get(currBoardId!)?.name;
  const [isEditPinboardNameDialogOpen, setIsEditPinboardNameDialogOpen] = useState(false);
  const [isDeletePinboardDialogOpen, setIsDeletePinboardDialogOpen] = useState(false);

  const editButtonId = `${id}-edit-button`;
  const deleteButtonId = `${id}-delete-button`;

  return (
    <>
      <PageHeader
        description={_(msg`Page to list pins for ${boardName} board`)}
        title={_(msg`Doowii - ${boardName} board`)}
      />
      <Flex css={{ flexDirection: "column", w: "full", flex: 1, p: "xl3", pt: 0, gap: "ld" }}>
        <Flex alignItems="center" gap="6">
          <Flex css={{ gap: "md" }}>
            <Heading as="h1" level={1}>
              {boardName}
            </Heading>
          </Flex>
          <HStack gap="2">
            <Tooltip content={_(msg`Edit pinboard name`)}>
              <Button
                aria-label={_(msg`Edit pinboard name`)}
                iconOnly={faPen}
                id={editButtonId}
                onClick={() => {
                  setIsEditPinboardNameDialogOpen(true);
                }}
                size="medium"
                variant="dropdown"
              />
            </Tooltip>
            <Tooltip content={_(msg`Delete pinboard`)}>
              <Button
                aria-label={_(msg`Delete pinboard`)}
                iconOnly={faTrash}
                id={deleteButtonId}
                onClick={() => {
                  setIsDeletePinboardDialogOpen(true);
                }}
                size="medium"
                variant="dropdown"
              />
            </Tooltip>

            {pinboardResults.length > 2 ? (
              <SearchInput
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
                variant="expandable"
              />
            ) : null}
          </HStack>
        </Flex>
        <Flex css={{ flexDirection: "column", flex: 1 }}>
          {loading ? (
            <Flex flex={1}>
              <LoadingLogo />
            </Flex>
          ) : (
            <Grid
              gap="xl"
              gridAutoRows="max-content"
              gridTemplateColumns={["repeat(auto-fill, minmax(min(100%, 500px), 1fr))"]}
              justifyContent="center"
              w="full"
            >
              {pinboardResults.length === 0 ? <PinboardSuggestionCard /> : null}
              {pinboardResults
                .filter((pin) => pin.title.toLowerCase().includes(searchQuery))
                .map((pin) => (
                  <PinboardResultCard key={pin.id} pin={pin} />
                ))}
            </Grid>
          )}
        </Flex>
      </Flex>

      <EditPinboardNameDialog
        boardId={currBoardId!}
        boardName={boardName!}
        isOpen={isEditPinboardNameDialogOpen}
        setIsOpen={setIsEditPinboardNameDialogOpen}
        triggerId={editButtonId}
      />

      <DeletePinboardDialog
        boardId={currBoardId!}
        isOpen={isDeletePinboardDialogOpen}
        setIsOpen={setIsDeletePinboardDialogOpen}
        triggerId={deleteButtonId}
      />
    </>
  );
};

export { Pinboard };
