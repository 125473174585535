/**
 * This module contains constants for feature flags stored in Firebase.
 *
 * The constants in this file are intended to match the features
 * available in the 'feature_flag' collection in Firebase. Each feature
 * flag represents a potential feature a user might have access to.
 *
 * Developers should add any new feature flags to this file. Once a feature
 * flag is added here, it can be imported into other parts of the application
 * and used for conditional rendering based on the user's feature access.
 *
 * For example, a component could import a feature flag from this file, and
 * then use that flag to conditionally render certain elements or control
 * certain behaviors. This allows for flexible, granular control of features
 * on a per-user basis.
 *
 * @module features
 */

export const APOLLO_V2 = "apollo_v2";
export const GOV_AND_EDU_ONLY = "gov_and_edu_only";
export const MANAGE_USERS = "manage_users";
