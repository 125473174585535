import { fetchAllData } from "@api/retriever";
import { ParentDocTypeEnum } from "@api/retriever.i";
import { t } from "@lingui/macro";
import { toast } from "doowii-ui";
import { saveAs } from "file-saver";

const sanitizeFilename = (filename: string): string => {
  filename = filename.toLowerCase();
  filename = filename.replace(/[^a-z0-9._-]/gu, " ");
  return filename;
};

interface DownloadDataProps {
  filename: string;
  docId: string;
  parentDocId: string;
  parentDocType: ParentDocTypeEnum;
}

const downloadData = async ({ filename, docId, parentDocId, parentDocType }: DownloadDataProps) => {
  try {
    toast({ status: "info", loading: true, title: t`Downloading data...` });

    const sanitizedFilename = sanitizeFilename(filename);

    const url = await fetchAllData(sanitizedFilename, docId, parentDocId, parentDocType);

    if (!url) {
      return;
    }

    const response = await fetch(url);
    const blob = await response.blob();
    saveAs(blob, filename);
    toast({ status: "success", title: t`Download successful` });
  } catch (e) {
    toast({ status: "error", title: t`Download failed` });
  }
};
export { downloadData };
