import { Box, BoxProps } from "@doowii/design-system/jsx";
import React, { PropsWithChildren } from "react";

type Container = BoxProps & React.RefAttributes<HTMLDivElement>;

const ThumbnailBackground: React.FC<
  PropsWithChildren<Container & { containerProps?: Container }>
> = ({ children, containerProps, ...props }) => (
  <Box
    {...containerProps}
    css={{
      position: "relative",
      overflow: "hidden",
      ...containerProps?.css,
    }}
  >
    <Box
      css={{
        position: "absolute",
        background: "base.blueBrandSecondary",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: 0,
        pointerEvents: "none",
      }}
    />
    <Box
      css={{
        position: "absolute",
        opacity: "10%",
        top: "-60%",
        left: "-30%",
        width: "368px",
        height: "368px",
        borderRadius: "full",
        background: "linear-gradient(97.52deg, #F6CD3C 47.37%, #29BDF0 65.41%)",
        zIndex: 0,
        pointerEvents: "none",
      }}
    />
    <Box
      css={{
        position: "absolute",
        opacity: "20%",
        bottom: "-10%",
        right: "-10%",
        width: "158px",
        height: "158px",
        borderRadius: "full",
        background: "linear-gradient(97.52deg, #29BDF0 47.66%, #F6CD3C 65.7%)",
        zIndex: 0,
        pointerEvents: "none",
      }}
    />

    <Box {...props} css={{ zIndex: 1, position: "relative", ...props.css }}>
      {children}
    </Box>
  </Box>
);

export { ThumbnailBackground };
