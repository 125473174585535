/* eslint-disable */
import axios from "axios";

import { env } from "../env";

import { withSentry } from "@utils/wrapper";
import { getCurrentUserAuthorization, sequalizerPost } from "./base";
import { SequalizerError } from "./sequalizer.i";


const callSequalizer = withSentry(
  async (
    _org_id,
    question,
    _user_id,
    recent_messages,
    thread_id,
    question_id,
    source_type,
    query_type,
    question_type = "user",
    abortController?
  ) => {
    try {
      const token = await getCurrentUserAuthorization();
      const response = await axios.post(
        `${env.REACT_APP_SEQUALIZER_API_URL}/process`,
        {
          question,
          source_type,
          recent_messages,
          thread_id,
          question_id,
          query_type,
          question_type,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          signal: abortController?.signal,
        }
      );
      return response.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        //@ts-ignore
         
        throw error;
      } else if (error.response) {
        throw new SequalizerError(
          error.response.data.detail.error_reason,
          error.response.data.detail.error,
        );
      } else if (error.request) {
        throw new Error("No response received from server");
      } else {
        throw error;
      }
    }
  }
);

const fetchSuggestions = withSentry(
  async (
    _org_id,
    previous_questions,
    source_type,
    query_type,
    totalQuestions = 4
  ) => {
    try {
      const currentPath = window.location.pathname;

      if (currentPath.startsWith("/canvas-ui")) {
        totalQuestions = 3;
      }

      const response = await sequalizerPost("/suggestions", {
        previous_questions,
        limit: totalQuestions,
        source_type,
        query_type,
      });
      return response.data.suggestions;
    } catch (error: any) {
      if (error.response) {
        // Handle error response from the server
        throw new Error(
          `Server responded with error: ${error.response.data.detail.error_reason}`
        );
      } else if (error.request) {
        // Handle no response received from the server
        throw new Error("No response received from server");
      } else {
        // Handle other errors
        throw error;
      }
    }
  }
);

const fetchFollowUpPrompts = withSentry(
  async ({ thread_id, question_id, recent_messages }) => {
    try {
      const response = await sequalizerPost("/refinements", {
        thread_id,
        question_id,
        recent_messages,
        limit: 3,
      });
      return response.data.questions;
    } catch (error) {
      console.error(error);
    }
  }
);

export {
  callSequalizer,
  fetchFollowUpPrompts,
  fetchSuggestions
};

